import { Action } from '../Action';
import { Auther } from '../../Auther';
import { JOIN_MOD_QUEUE } from '../../Routes/Routes';
import { RouteBuilder } from '../../Routes/RouteBuilder';

export interface IJoin {
    placeId: string,
    placeNumber: number,
    templateId: string,
}

export class Join extends Action<IJoin> {

    private readonly id: string;

    constructor(auther: Auther, id: string) {
        super(JOIN_MOD_QUEUE, auther);
        this.id = id;
        this.actionConf.rateLimitTime = 1000;
        this.actionConf.announceRateLimit = true;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(this.id);
    }

}