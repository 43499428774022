import { Action } from '../Action';
import { Auther } from '../../Auther';
import { GET_TRANSACTION } from '../../Routes/Routes';
import { Transaction } from '../../EntityTypes/Mint';
import { RouteBuilder } from '../../Routes/RouteBuilder';

export class GetTransaction extends Action<Transaction> {

    private readonly txnId: string;

    constructor(auther: Auther, txnId: string) {
        super(GET_TRANSACTION, auther);
        this.txnId = txnId;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(this.txnId);
    }

}