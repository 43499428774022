import { Action } from '../Action';
import { Auther } from '../../Auther';
import { RouteBuilder } from '../../Routes/RouteBuilder';
import { EDIT_TOKEN_UTILITY_VIDEO } from '../../Routes/Routes';
import { TokenUtilityVideo } from '../../EntityTypes/TokenUtilityVideo';

export interface EditUtilityVideoParams {
  title: string;
  description: string;
}

export class EditUtilityVideo extends Action<TokenUtilityVideo, EditUtilityVideoParams> {

    private readonly tokenId: string;
    private readonly videoId: string;

    constructor(bridge: Auther, tokenId: string, videoId: string, params: EditUtilityVideoParams) {
        super(EDIT_TOKEN_UTILITY_VIDEO, bridge, params);
        this.tokenId = tokenId;
        this.videoId = videoId;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(
            this.tokenId,
            this.videoId,
        );
    }

}