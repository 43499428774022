
export class RouteBuilder {

    private base: string;

    private endpoint: string;

    private readonly params: {
        [key: string]: string
    };

    private constructor() {
        this.base = '';
        this.endpoint = '';
        this.params = {};
    }

    public static create(): RouteBuilder {
        return new RouteBuilder();
    }

    public withBase(base: string): RouteBuilder {
        this.base = base;
        return this;
    }

    public withEndpoint(endpoint: string): RouteBuilder {
        this.endpoint = endpoint;
        return this;
    }

    public withParam(key: string, value: string): RouteBuilder {
        this.params[key] = value;
        return this;
    }

    public build(): string {
        const base = this.base.endsWith('/') ? this.base : `${ this.base }/`;
        let endpoint = this.endpoint.startsWith('/') ? this.endpoint.substring(1) : this.endpoint;
        endpoint = endpoint.endsWith('/') ? endpoint.substring(0, endpoint.length - 1) : endpoint;
        const params = Object.keys(this.params).reduce((acc, key) => acc + `${ acc.length > 0 ? '&' : '?' }${ key }=${ this.params[key] }`, '');

        return `${ base }${ endpoint }${ params }`;
    }

}