import { Action } from './Action';
import { Auther } from '../Auther';
import { LINKED_APPS } from '../Routes/Routes';
import { LinkedApp } from '../EntityTypes/LinkedApp';

export class GetLinkedApps extends Action<Array<LinkedApp>> {

    constructor(auther: Auther) {
        super(LINKED_APPS, auther);
    }

}