import { Action } from './Action';
import { GET_CHAIN_LINK } from '../Routes/Routes';
import { Auther } from '../Auther';
import { RouteBuilder } from '../Routes/RouteBuilder';

interface ChainLinkData {
    address: string,
    chainName: string,
    data: any,
}

export class GetChainLink extends Action<ChainLinkData> {

    private readonly chainName: string;

    constructor(bridge: Auther, chainName: string) {
        super(GET_CHAIN_LINK, bridge);
        this.chainName = chainName;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route
            .compileRoute(this.chainName);
    }


}