import React from 'react';
import Head from 'next/head';

interface HeadSeoProps {
  url: string;
  type: string;
  title: string;
  description: string;

  image: string;
  imageWidth?: number;
  imageHeight?: number;
  imageType?: string;

  children?: React.ReactNode;
}

export default function HeadSeo({
    url,
    type,
    title,
    description,

    image,
    imageWidth,
    imageHeight,
    imageType,

    /* Extra tags */
    children,
}: HeadSeoProps) {
    return (
        <Head>
            <title>{title} | MyMetaverse</title>

            <link rel="canonical" href={ url } />

            <meta key="og:type" property="og:type" content={ type } />
            <meta key="og:title" property="og:title" content={ title } />
            <meta key="og:description" property="og:description" content={ description } />
            <meta key="og:url" property="og:url" content={ url } />
            <meta key="og:site_name" property="og:site_name" content="MyMetaverse" />

            <meta key="og:image" property="og:image" content={ image } />
            {imageWidth && <meta key="og:image:width" property="og:image:width" content={ String(imageWidth) } />}
            {imageHeight && <meta key="og:image:height" property="og:image:height" content={ String(imageHeight) } />}
            {imageType && <meta key="og:image:type" property="og:image:type" content={ imageType } /> }

            <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
            <meta key="twitter:creator" name="twitter:creator" content="@mymetaverse" />
            <meta key="twitter:site" name="twitter:site" content="@mymetaverse" />
            <meta key="twitter:title" name="twitter:title" content={ title } />
            <meta key="twitter:description" name="twitter:description" content={ description } />
            <meta key="twitter:image" name="twitter:image" content={ image } />
            <meta key="twitter:url" property="twitter:url" name={ url } />

            <meta key="description" name="description" content={ description } />
            <meta key="schemaimage" itemProp="image" content={ image } />
            <meta key="schemaname" itemProp="name" content={ title + '| MyMetaverse' } />
            <meta key="schemadescription" itemProp="description" content={ description } />

            {children}
        </Head>
    );
}