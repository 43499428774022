import { Action } from './Action';
import { Auther } from '../Auther';
import { SHOP_ITEMS } from '../Routes/Routes';

export interface ShopItem {
    id: string,
    name: string,
    description: string,
    cost: number,
    imageUri: string,
    tag?: string,
}

export class GetShopItems extends Action<Array<ShopItem>> {

    constructor(bridge: Auther) {
        super(SHOP_ITEMS, bridge);
        this.removeAuthorization();
    }

}