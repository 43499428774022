import { Auther } from '../../Auther';
import { MintNft, MintProps } from './MintNft';
import { GetTransaction } from './GetTransaction';
import { GetQueueTransaction } from './GetQueueTransaction';
import { WithdrawAction, WithdrawProps } from './WithdrawAction';
import { GetPendingQueues } from './GetPendingQueues';


export class MintActions {

    private readonly auther: Auther;

    constructor(auther: Auther) {
        this.auther = auther;
    }

    public mint(mint: MintProps) {
        return new MintNft(this.auther, mint);
    }

    public getTransaction(txnId: string) {
        return new GetTransaction(this.auther, txnId);
    }

    public getQueueTransaction(mintId: string) {
        return new GetQueueTransaction(this.auther, mintId);
    }

    public withdraw(props: WithdrawProps) {
        return new WithdrawAction(this.auther, props);
    }

    public getPendingQueues() {
        return new GetPendingQueues(this.auther);
    }

}