import { Action } from './Action';
import { SensitiveDetails } from '../EntityTypes/SensitiveDetails';
import { SENSITIVE_DETAILS } from '../Routes/Routes';
import { Auther } from '../Auther';


export class RequestSensitiveDetails extends Action<SensitiveDetails> {

    constructor(bridge: Auther) {
        super(SENSITIVE_DETAILS, bridge);
    }

}