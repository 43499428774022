import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { MetaAPI } from '../API/MetaAPI';
import { TokenToSave } from '../API/ApiTypes';

interface ILogged {
    username: string,
    userId: number
}

interface IAuthContext {
    logged?: ILogged;
    // eslint-disable-next-line no-unused-vars
    setLogged?: (logged: TokenToSave) => void;

    // eslint-disable-next-line no-unused-vars
    logout?: (redirect?: boolean) => void;
}

const AuthContext = createContext<IAuthContext>({});

export const AuthProvider = ({ children }: { children: ReactNode }) => {

    const [ logged, setLoggedImpl ] = useState<ILogged | undefined>();
    const [ , setIsLoading ] = useState(true);
    const router = useRouter();

    useEffect(() => {
        const api = MetaAPI.getInstance();

        async function runAuth() {
            // First we load the token from the local storage.
            api.auther.tokenManager.loadToken();

            // Then we attempt to review if it needs any kind of refresh.
            await api.auther.refreshTokenReview();

            // If the token is valid, we load it into the state.
            if (api.auther.tokenManager.isLoggedIn()) {
                const tokenDetails = MetaAPI.getInstance().auther.tokenManager.getToken();
                setLoggedImpl({
                    userId: tokenDetails.userId,
                    username: tokenDetails.username,
                });
            } else
                setLoggedImpl(undefined);

        }

        runAuth().then(() => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            console.error('Error while revalidating the token: ', error);
        });
    }, []);

    const logout = (redirect = true) => {
        MetaAPI.getInstance().auther.tokenManager.logout();
        setLoggedImpl(undefined);
        if (redirect)
            router.push('/');
    };

    const setLogged = (details: TokenToSave) => {
        setLoggedImpl({
            username: details.username,
            userId: details.userId,
        });
        MetaAPI.getInstance().auther.tokenManager.saveToken(details);
    };

    return (
        <AuthContext.Provider
            value={ {
                logged,
                setLogged,
                logout,
            } }
        >
            { children }
        </AuthContext.Provider>
    );
};

export function useAuth(): IAuthContext {
    return useContext(AuthContext);
}

export function useIsAuth(): boolean {
    const auth = useAuth();
    return !!auth.logged;
}
