import MobileHeader from '../MobileHeader/MobileHeader';
import Header from '../Header/Header';
import styles from './HeaderWrapper.module.scss';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import { MetaAPI } from '../../../API/MetaAPI';
import { useCarbon } from '../../../contexts/CarbonContext';

export type Route = {
    name: string,
    link?: string,
    sub?: Array<Route>
}

const routes: Array<Route> = [
    {
        name: 'Games',
        link: '/games?scrollGames=true',
    },
    {
        name: 'NFTs',
        link: '/nfts',
    },
    {
        name: 'News',
        link: '/news',
    },
    {
        name: 'About',
        sub: [
            {
                name: 'Carbon',
                link: '/learn/carbon-sink-NFTs',
            },
            {
                name: 'Play & Earn',
                link: '/learn/play-and-earn',
            },
            {
                name: 'Whitepaper',
                link: 'https://whitepaper.mymetaverse.io/',
            },
            {
                name: 'Support',
                link: '/help',
            },
            {
                name: 'Contact Us',
                link: '/contact-us',
            },
        ],
    },
];

export default function HeaderWrapper() {

    const [ isDesktop, setIsDesktop ] = useState(false);
    const auth = useAuth();


    const { data } = MetaAPI.getInstance().requestSensitiveDetails().useSWR()({
        revalidateOnFocus: false,
    });

    const { amount: carbon } = useCarbon();

    useEffect(() => {
        const updateMedia = () => {
            setIsDesktop(window.innerWidth >= 1100);
        };
        updateMedia();

        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    }, []);

    return (
        <header className={ styles.headerContainer }>
            {
                isDesktop
                    ? <Header
                        className={ styles.normalHeaderView }
                        user={ auth.logged?.username }
                        email={ data?.email }
                        carbon={ carbon }
                        routes={ routes }
                    />
                    : <MobileHeader
                        className={ styles.mobileHeaderView }
                        user={ auth.logged?.username }
                        email={ data?.email }
                        carbon={ carbon }
                        routes={ routes }
                    />
            }

        </header>
    );
}