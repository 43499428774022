import { Action } from '../Action';
import { SHOP_CIRCLE_PUBKEY } from '../../Routes/Routes';
import { Auther } from '../../Auther';


export class GetCirclePubKey extends Action<{
    data: {
        keyId: string,
        publicKey: string
    }
}> {

    constructor(auther: Auther) {
        super(SHOP_CIRCLE_PUBKEY, auther);
    }

}