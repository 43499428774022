import { Action } from './Action';
import { ProcessLinkingLinkResult } from '../EntityTypes/ProcessLinkingLinkResult';
import { Auther } from '../Auther';
import { PROCESS_LINKING_LINK } from '../Routes/Routes';

export interface ProcessLinkingLinkBody {
    linkingId: string,
}

export class ProcessLinkingLink extends Action<ProcessLinkingLinkResult, ProcessLinkingLinkBody> {

    constructor(bridge: Auther, props: ProcessLinkingLinkBody) {
        super(PROCESS_LINKING_LINK, bridge, props);
    }

}