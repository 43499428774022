import Image from 'next/image';
import React from 'react';

interface Props {
    className?: string,
    bgSrc: string,
    children?: React.ReactNode,
    divProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    // eslint-disable-next-line no-undef
    imageProps?: any
}

export default function DivBg({
    className,
    bgSrc,
    children,
    divProps,
    imageProps,
}: Props) {
    return (
        <div className={ className } { ...divProps }>
            <div>
                <Image
                    priority
                    src={ bgSrc }
                    layout={ 'fill' }
                    objectFit={ 'cover' }
                    { ...imageProps }
                    alt={ 'Background' }
                    placeholder='blur'
                    blurDataURL={ bgSrc }
                    color='purple'
                />
            </div>
            {children}
        </div>
    );
}