import { Action } from './Action';
import { UPDATE_TOKEN_DETAILS } from '../Routes/Routes';
import { Auther } from '../Auther';
import { Metadata } from '../EntityTypes/Wallet';
import { RouteBuilder } from '../Routes/RouteBuilder';

export interface UpdateTokenDetailsParams {
    tokenIndex: string,
    name?: string,
    description?: string
}

export class UpdateTokenDetails extends Action<Metadata, UpdateTokenDetailsParams> {

    private readonly tokenId: string;

    constructor(bridge: Auther, tokenId: string, params: UpdateTokenDetailsParams) {
        super(UPDATE_TOKEN_DETAILS, bridge, params);
        this.tokenId = tokenId;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(this.tokenId);
    }

}