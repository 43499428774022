import { Action } from '../Action';
import { Auther } from '../../Auther';
import { P2EClient, P2EClientTier } from '../../EntityTypes/P2E';
import { GET_CLIENT_INFO } from '../../Routes/Routes';
import { RouteBuilder } from '../../Routes/RouteBuilder';

export class GetP2EClient extends Action<P2EClient> {

    private readonly id: string;

    constructor(bridge: Auther, id: string) {
        super(GET_CLIENT_INFO, bridge);
        this.id = id;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(this.id);
    }

    protected handleResponse(response: P2EClient): P2EClient {
        const oldTiers = response.tiers;

        const tiers = oldTiers.reduce((acc, tier, i) => {
            const newTier = {
                ...tier,
                prevTier: acc[i - 1],
            };
            acc.push(newTier);
            return acc;
        }, [] as P2EClientTier[]);

        return {
            ...response,
            tiers,
        };
    }

}