import { Action } from '../Action';
import { Auther } from '../../Auther';
import { SHOP_GET_PAYMENT } from '../../Routes/Routes';
import { RouteBuilder } from '../../Routes/RouteBuilder';

export interface PaymentDetails {
    userId: number,
    status: string,
    reason: string,
}

export class GetPayment extends Action<PaymentDetails> {

    private readonly paymentId: string;

    constructor(bridge: Auther, paymentId: string) {
        super(SHOP_GET_PAYMENT, bridge);
        this.paymentId = paymentId;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(this.paymentId);
    }

}