import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAstronaut } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faTelegram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import styles from './MobileHeader.module.scss';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import BurgerButton from '../../common/BurgerButton/BurgerButton';
import Link from 'next/link';
import HeaderUserDropdown from '../HeaderUserDropdown/HeaderUserDropdown';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { useRouteChange } from '../../../hooks/useRouteChange';
import md5 from 'blueimp-md5';
import { useIsAuth } from '../../../contexts/AuthContext';
import { ParseCarbon } from '../../../utils/ParseCarbon';
import { MobileMenuList } from './MobileMenuList';
import { Route } from '../HeaderWrapper/HeaderWrapper';

export default function MobileHeader({ className, user, email, carbon, routes }: { className: string, user?: string, email?: string, carbon?: number, routes: Array<Route> }) {

    const [ showMenu, setShowMenu ] = useState(false);

    const dropdownContainer = useRef<HTMLDivElement>(null);
    const [ isActiveDropdown, setIsActiveDropdown ] = useOutsideClick(dropdownContainer, false);
    useRouteChange(() => setIsActiveDropdown(false));
    useRouteChange(() => {
        setShowMenu(false);
    });

    const isAuth = useIsAuth();

    useEffect(() => {
        if (showMenu)
            document.body.style.overflow = 'hidden';
        else
            document.body.style.overflow = 'unset';
    }, [ showMenu ]);

    const openDropdown = () => setIsActiveDropdown(!isActiveDropdown);

    const handleMenuToggle = () => {
        setShowMenu(!showMenu);
    };


    const profilePhoto = useMemo(() => `https://gravatar.com/avatar/${ md5(email?.toLowerCase().trim() || 'asd') }?s=65&d=identicon`, [ email ]);

    return (
        <>
            <div className={ `${ styles.header } ${ className }` }>

                <div className={ styles.headerWrapper }>

                    <div className={ styles.logo }>
                        <Link href='/' passHref>
                            <a>
                                <Image
                                    src='/logos/main.png'
                                    alt='MyMetaverse Logo'
                                    priority
                                    layout={ 'fill' }
                                    objectFit={ 'contain' }
                                />
                            </a>
                        </Link>
                    </div>


                    <div className={ styles.menu }>

                        <div className={ styles.menuAlign }>
                            <div className={ styles.rightMenuMinimized }>
                                <div>
                                    {
                                        !user
                                            ? <Link href="/login" passHref>
                                                <a className={ styles.icon }>
                                                    <FontAwesomeIcon icon={ faUserAstronaut } />
                                                </a>
                                            </Link>
                                            : <div className={ styles.userDetails }>
                                                <div className={ styles.contentUD }>
                                                    <button className={ styles.pic } onClick={ openDropdown }>
                                                        <img
                                                            src={ profilePhoto }
                                                            width={ 30 }
                                                            height={ 30 }
                                                            alt={ `${ user } profile image.` }
                                                        />
                                                    </button>
                                                </div>

                                                {
                                                    isActiveDropdown
                                                        ? <HeaderUserDropdown className={ styles.userDropdown } ref={ dropdownContainer } />
                                                        : []
                                                }

                                            </div>
                                    }
                                </div>
                            </div>
                            <BurgerButton active={ showMenu } className={ styles.burgerBtn } onClick={ () => handleMenuToggle() } />
                        </div>

                    </div>

                </div>

                {
                    showMenu
                        ? <div className={ styles.menuView }>
                            <div className={ `${ styles.menuList } font20` }>
                                <MobileMenuList
                                    options={ routes }
                                />
                            </div>
                            <div>
                                <div className={ styles.carbonAmount }>
                                    <div className={ styles.amount }>
                                        <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.39969 0C5.89747 0 6.29964 0.391836 6.29964 0.875V1.82273C7.16865 1.96438 7.96454 2.30754 8.6395 2.8C9.03886 3.08984 9.1176 3.63945 8.81949 4.025C8.49607 4.41328 7.9561 4.48984 7.55957 4.2C6.95772 3.75977 6.21246 3.5 5.39969 3.5C3.41137 3.5 1.7999 5.0668 1.7999 7C1.7999 8.9332 3.41137 10.5 5.39969 10.5C6.21246 10.5 6.95772 10.2402 7.55957 9.8C7.9561 9.51016 8.49607 9.58672 8.81949 9.975C9.1176 10.3605 9.03886 10.8855 8.6395 11.2C7.96454 11.6922 7.16865 12.0367 6.29964 12.1762V13.125C6.29964 13.609 5.89747 14 5.39969 14C4.90191 14 4.49974 13.609 4.49974 13.125V12.1762C1.94586 11.7605 0 9.60039 0 7C0 4.39961 1.94586 2.23918 4.49974 1.82273V0.875C4.49974 0.391836 4.90191 1.64063e-05 5.39969 1.64063e-05V0Z" fill="white" />
                                        </svg>
                                        {
                                            isAuth
                                                ? ParseCarbon.parse(carbon)
                                                : '0 KG'
                                        }
                                    </div>
                                    <Link href={ '/carbon/shop' }>
                                        <a className={ styles.topUp }>
                                            <span>TOP UP</span>
                                        </a>
                                    </Link>
                                </div>
                            </div>
                            <div className={ styles.menuFooter }>
                                <div className={ styles.social }>
                                    <div>
                                        <a href='https://twitter.com/MyMetaverse' target='_blank' rel="noreferrer noopener"><FontAwesomeIcon icon={ faTwitter } /></a>
                                    </div>
                                    <div>
                                        <a href='https://www.youtube.com/mymetaverse' target='_blank' rel="noreferrer noopener" ><FontAwesomeIcon icon={ faYoutube } /></a>
                                    </div>
                                    <div>
                                        <a href='https://discord.gg/DJTzX83QnJ' target='_blank' rel='noreferrer noopener'><FontAwesomeIcon icon={ faDiscord } /></a>
                                    </div>
                                    <div>
                                        <a href='https://t.me/MyMetaverse' target='_blank' rel='noreferrer noopener'><FontAwesomeIcon icon={ faTelegram } /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : []
                }
            </div>
        </>
    );
}
