import { Action } from './Action';
import { Auther } from '../Auther';
import { CONTACT_REQUEST } from '../Routes/Routes';

export interface ContactRequestParams {
  token: string;

  email: string;
  content: string;
  companyName: string;
  companySize: string;
  companyWebsite: string;
  contactPosition: string;
}

export class ContactRequest extends Action<{ message: string }, ContactRequestParams> {

    private params: ContactRequestParams;

    constructor(bridge: Auther, params: ContactRequestParams) {
        super(CONTACT_REQUEST, bridge, params);
        this.params = params;
    }

    getBody() {
        const sizes = {
            25: '1-5',
            50: '5-50',
            75: '50-1000',
            100: '1000+',
        };

        return {
            token: this.params.token,
            email: this.params.email,
            content: this.params.content,
            companyName: this.params.companyName,
            companyWebsite: this.params.companyWebsite,
            contactPosition: this.params.contactPosition,
            companySize: sizes[this.params.companySize] || sizes[25],
        };
    }
}