import '../styles/globals.scss';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import Layout from '../components/common/Layout';
import { AuthProvider } from '../contexts/AuthContext';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CarbonProvider } from '../contexts/CarbonContext';
import { MintProvider } from '../contexts/MintContext/MintContext';
import { SWRConfig } from 'swr';
import axios, { AxiosError } from 'axios';
import { MetaAPI } from '../API/MetaAPI';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';


import type { ReactElement, ReactNode } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';

import ComingSoonView from '../components/views/ComingSoon/coming-soon';

dayjs.extend(relativeTime);

config.autoAddCss = false;

type NextPageWithLayout = NextPage & {
    // eslint-disable-next-line no-unused-vars
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}


function Usables({ children }: {
    children: ReactNode
}) {
    return (
        <AuthProvider>
            <CarbonProvider>
                <MintProvider>
                    { children }
                </MintProvider>
            </CarbonProvider>
        </AuthProvider>
    );

}

function MyMetaverse({ Component, pageProps }: AppPropsWithLayout) {

    const Print = () =>
        Component.getLayout
            ? <>{Component.getLayout(<Component { ...pageProps } />)}</>
            : <Layout><Component { ...pageProps } /></Layout>;

    if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === '1')
        return <ComingSoonView display={ 'UNDER CONSTRUCTION' } />;

    return (
        <SWRConfig
            value={ {
                onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                    if (retryCount >= 3)
                        return;

                    if (axios.isAxiosError(error)) {
                        const axiosError = error as AxiosError;

                        if (!axiosError.response)
                            return;

                        if (axiosError.response.status === 404)
                            return;
                        if (axiosError.response.status === 403)
                            MetaAPI.getInstance().auther.refreshTokenWithLock(true)
                                .then(() => {
                                    revalidate({ retryCount });
                                });
                    } else
                        setTimeout(() => revalidate({ retryCount }), 2000);
                },
            } }
        >
            <ToastContainer
                position='top-right'
                autoClose={ 3000 }
                hideProgressBar={ false }
                newestOnTop={ true }
                closeOnClick
                draggable
                pauseOnHover
                style={ {
                    zIndex: 100000000,
                } }
            />
            <Usables>
                <Print />
            </Usables>
        </SWRConfig>
    );
}

export default MyMetaverse;
