import { Auther } from '../../Auther';
import { WITHDRAW_TOKEN } from '../../Routes/Routes';
import { MFAAction, MFAProps } from '../wrappers';

export interface WithdrawProps extends MFAProps {
    tokens: Array<{
        id: string,
        indexes?: Array<string>,
        amount?: number,
    }>,
}

interface WithdrawResult {
    id: string,
    tid: string,
}

export class WithdrawAction extends MFAAction<WithdrawResult, WithdrawProps> {

    private readonly props: WithdrawProps;

    constructor(bridge: Auther, props: WithdrawProps) {
        super(WITHDRAW_TOKEN, bridge);
        this.props = props;
    }

    getBody(): WithdrawProps {
        return this.props;
    }

}