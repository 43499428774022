import { Auther } from '../Auther';
import { Action } from './Action';
import { GET_HISTORY_HYDRATE } from '../Routes/Routes';
import { RouteBuilder } from '../Routes/RouteBuilder';

export interface GetHistoryHydrateProps {
    searchType: string,
    searchValue: string,
}

export class GetHistoryHydrate extends Action<{ displayName: string, status: string }, GetHistoryHydrateProps> {

    private readonly swapId: string;

    constructor(auther: Auther, swapId: string, body: GetHistoryHydrateProps) {
        super(GET_HISTORY_HYDRATE, auther, body);
        this.swapId = swapId;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route
            .compileRoute(this.swapId);
    }

}