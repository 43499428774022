import { Action } from '../Action';
import { Auther } from '../../Auther';
import { RouteBuilder } from '../../Routes/RouteBuilder';
import { UtilityVote } from '../../EntityTypes/UtilityVote';
import { VOTE_TOKEN_UTILITY_VIDEO } from '../../Routes/Routes';

export interface VoteForUtilityVideoParams {
  videoId: string;
  positive: boolean
}

export class VoteForUtilityVideo extends Action<UtilityVote, VoteForUtilityVideoParams> {

    private readonly tokenId: string;

    constructor(bridge: Auther, tokenId: string, params: VoteForUtilityVideoParams) {
        super(VOTE_TOKEN_UTILITY_VIDEO, bridge, params);
        this.tokenId = tokenId;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(
            this.tokenId,
        );
    }

}