import { Action } from '../Action';
import { Auther } from '../../Auther';
import { MINT_CREATE } from '../../Routes/Routes';

interface TokenMetadata {
    tokenName: string,
    tokenDescription: string,
    maxSupply: number,
}

export interface MintProps {
    tokenMetadata: TokenMetadata,
    tokenImage: File
}

export interface MintResult {
    mintQueueId: string,
    lastTransaction: string,
    mintOnDemandId: string,
}

export class MintNft extends Action<MintResult, FormData> {

    private readonly tokenMetadata: TokenMetadata;
    private readonly file: File;

    constructor(bridge: Auther, props: MintProps) {
        super(MINT_CREATE, bridge);
        this.tokenMetadata = props.tokenMetadata;
        this.file = props.tokenImage;
    }

    getBody(): FormData {
        const formData = new FormData();
        formData.set('tokenMetadata', JSON.stringify(this.tokenMetadata));
        formData.set('tokenImage', this.file);
        return formData;
    }

}