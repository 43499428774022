import { Route } from './Route';


const SENSITIVE_DETAILS = new Route('/me', 'GET');
const SENSITIVE_DETAILS_UPDATE = new Route('/me', 'POST');
const GET_ADOPTION_DETAILS = new Route('/adoption/{id}', 'GET');
const GET_ADOPTIONS = new Route('/adoption/', 'GET');

const GET_WALLET = new Route('/wallet', 'GET');
const GET_METACITIZEN = new Route('/metacitizen', 'GET');
const PICK_METACITIZEN = new Route('/metacitizen', 'PUT');
const UPDATE_TOKEN_DETAILS = new Route('/token/{id}/details', 'PUT');
const UPDATE_TOKEN_PROP = new Route('/token/{tokenId}/index/{tokenIndex}/properties/{propertyName}', 'PUT');
const TOKEN_SIGN = new Route('/token/{tokenId}/index/{tokenIndex}/sign', 'PUT');

const GET_TOKEN_UTILITY_VIDEOS = new Route('/token/{tokenId}/videos/all', 'GET');
const GET_TOKEN_TOP3_VIDEOS = new Route('/token/{tokenId}/videos/top3', 'GET');
const ADD_TOKEN_UTILITY_VIDEO = new Route('/token/{tokenId}/videos/add', 'POST');
const EDIT_TOKEN_UTILITY_VIDEO = new Route('/token/{tokenId}/videos/{videoId}/edit', 'PUT');
const VOTE_TOKEN_UTILITY_VIDEO = new Route('/token/{tokenId}/videos/vote', 'PUT');
const DELETE_TOKEN_UTILITY_VIDEO = new Route('/token/{tokenId}/videos/{videoId}', 'DELETE');

const PROCESS_LINKING_LINK = new Route('/linking-link', 'POST');
const LINKING_LINK_INTROSPECTION = new Route('/linking-link/{linkingId}', 'GET');

const LINKED_APPS = new Route('/wallet/linked-apps', 'GET');

const SHOP_ITEMS = new Route('/shop', 'GET');
const SHOP_GET_ALL_PAYMENTS = new Route('/shop/payment/', 'GET');
const SHOP_CREATE_PAYMENT = new Route('/shop/payment', 'POST');
const SHOP_CAPTURE_PAYMENT = new Route('/shop/payment/{paymentId}', 'POST');
const SHOP_GET_PAYMENT = new Route('/shop/payment/{paymentId}', 'GET');
const SHOP_DELETE_PAYMENT = new Route('/shop/payment/{paymentId}', 'DELETE');

const SHOP_CIRCLE_PUBKEY = new Route('/shop/circle/pubkey', 'GET');
const SHOP_CIRCLE_CREDIT_CARDS = new Route('/shop/circle/card', 'GET');
const SHOP_CIRCLE_CREDIT_CARD = new Route('/shop/circle/card/{cardId}', 'GET');
const SHOP_CIRCLE_DELETE_CREDIT_CARD = new Route('/shop/circle/card/{cardId}', 'DELETE');
const SHOP_CIRCLE_CREATE_CARD = new Route('/shop/circle/card', 'POST');

const GET_CARBON = new Route('/carbon', 'GET');
const GET_CARBON_BANKS = new Route('/carbon-banks', 'GET');
const GET_CARBON_BANKS_LIST = new Route('/carbon-banks/list', 'GET');

const GET_TRANSACTION = new Route('/create/transaction/{id}', 'GET');
const GET_QUEUE_TRANSACTION = new Route('/create/queuelaststate/{id}', 'GET');
const MINT_CREATE = new Route('/create/nft', 'POST');
const WITHDRAW_TOKEN = new Route('/token/withdraw', 'POST');

const EVENTSOURCE_AUTH = new Route('/eventsource/authenticate', 'POST');

const ADD_GAME_REQUEST = new Route('/game-request', 'POST');
const CONTACT_REQUEST = new Route('/contact-request', 'POST');

const GET_PENDING_MINT_QUEUES = new Route('/create/pending', 'GET');
const GET_HISTORY = new Route('/history', 'GET');
const GET_HISTORY_HYDRATE = new Route('/history/hydrate/{swapId}', 'GET');

// MINT ON DEMAND
const GET_MOD_TEMPLATES = new Route('/mod', 'GET');
const GET_MOD_TEMPLATE = new Route('/mod/{id}', 'GET');
const PING_MOD = new Route('/mod/ping', 'POST');
const MINT_MOD = new Route('/mod/{id}/execute', 'POST');
const JOIN_MOD_QUEUE = new Route('/mod/{id}/join', 'POST');
const LEAVE_MOD_QUEUE = new Route('/mod/', 'DELETE');

// PLAY AND EARN
const GET_P2E_BANK = new Route('/p2e', 'GET');
const GET_P2E_EARNED_AMOUNT = new Route('/p2e/earned', 'GET');
const GET_CLIENT_INFO = new Route('/p2e/{clientId}', 'GET');
const GET_CLIENT_LEADERBOARD = new Route('/p2e/{clientId}/leaderboard', 'GET');
const GET_USER_STAKE_INFO = new Route('/p2e/user/stake', 'GET');
const STAKE_FOR_CLIENT = new Route('/p2e/{clientId}/stake', 'PUT');

// CHAIN LINK
const GET_CHAIN_LINK = new Route('/chain-link/{chainName}', 'GET');
const CREATE_CHAIN_LINK = new Route('/chain-link/{chainName}', 'POST');
const GET_LINKED_CHAINS = new Route('/chain-link', 'GET');

export {
    SENSITIVE_DETAILS,
    GET_HISTORY,
    SENSITIVE_DETAILS_UPDATE,
    GET_ADOPTION_DETAILS,
    PROCESS_LINKING_LINK,
    LINKING_LINK_INTROSPECTION,
    GET_WALLET,
    GET_METACITIZEN,
    GET_ADOPTIONS,
    PICK_METACITIZEN,
    LINKED_APPS,

    UPDATE_TOKEN_DETAILS,
    UPDATE_TOKEN_PROP,
    TOKEN_SIGN,

    GET_TOKEN_UTILITY_VIDEOS,
    GET_TOKEN_TOP3_VIDEOS,
    ADD_TOKEN_UTILITY_VIDEO,
    EDIT_TOKEN_UTILITY_VIDEO,
    VOTE_TOKEN_UTILITY_VIDEO,
    DELETE_TOKEN_UTILITY_VIDEO,

    ADD_GAME_REQUEST,
    CONTACT_REQUEST,

    SHOP_CREATE_PAYMENT,
    SHOP_CAPTURE_PAYMENT,
    SHOP_DELETE_PAYMENT,
    SHOP_GET_ALL_PAYMENTS,
    SHOP_GET_PAYMENT,
    SHOP_ITEMS,
    SHOP_CIRCLE_PUBKEY,
    SHOP_CIRCLE_CREDIT_CARDS,
    SHOP_CIRCLE_CREATE_CARD,
    SHOP_CIRCLE_CREDIT_CARD,
    SHOP_CIRCLE_DELETE_CREDIT_CARD,
    GET_CARBON,
    GET_CARBON_BANKS,
    MINT_CREATE,
    GET_TRANSACTION,
    GET_QUEUE_TRANSACTION,
    WITHDRAW_TOKEN,
    EVENTSOURCE_AUTH,
    GET_HISTORY_HYDRATE,
    GET_PENDING_MINT_QUEUES,

    // MINT ON DEMAND
    GET_MOD_TEMPLATE,
    GET_MOD_TEMPLATES,
    MINT_MOD,
    PING_MOD,
    JOIN_MOD_QUEUE,
    LEAVE_MOD_QUEUE,

    GET_CARBON_BANKS_LIST,

    // PLAY AND EARN
    GET_P2E_BANK,
    GET_CLIENT_INFO,
    GET_P2E_EARNED_AMOUNT,
    GET_CLIENT_LEADERBOARD,
    GET_USER_STAKE_INFO,
    STAKE_FOR_CLIENT,

    GET_CHAIN_LINK,
    CREATE_CHAIN_LINK,
    GET_LINKED_CHAINS,

};