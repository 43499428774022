import { Action } from '../Action';
import { Auther } from '../../Auther';
import { RouteBuilder } from '../../Routes/RouteBuilder';
import { GET_TOKEN_UTILITY_VIDEOS } from '../../Routes/Routes';
import { TokenUtilityVideo } from '../../EntityTypes/TokenUtilityVideo';

export interface GetTokenVideosParams {
    tokenId: string
}

export class GetTokenVideos extends Action<{ videos: Array<TokenUtilityVideo> }> {

  private readonly params: GetTokenVideosParams;

  constructor(bridge: Auther, params: GetTokenVideosParams) {
      super(GET_TOKEN_UTILITY_VIDEOS, bridge);
      this.params = params;
  }

  getCompiledRoute(): RouteBuilder {
      return this.route.compileRoute(
          this.params.tokenId,
      );
  }

}