import cn from 'classnames';
import { MouseEvent, MouseEventHandler, ReactNode, useState } from 'react';

import style from './MetaButton.module.scss';

export interface MetaButtonProps {
    value?: string;
    className?: string;
    children?: ReactNode;
    type?: 'submit' | 'reset' | 'button';
    onClick?: MouseEventHandler<HTMLButtonElement>;
    // eslint-disable-next-line no-unused-vars
    disabled?: boolean | ((ev: MouseEvent<HTMLButtonElement>) => void);
    rateLimitTime?: number,
}

export default function MetaButton({ value, className, onClick, children, disabled, type, rateLimitTime = 0 }: MetaButtonProps) {

    const [ rateLimiter, setRateLimiter ] = useState<number>();

    const clickHandler = (ev: MouseEvent<HTMLButtonElement>) => {

        if (rateLimitTime) {
            const now = Date.now();

            if (rateLimiter && now - rateLimiter <= rateLimitTime) {
                ev.preventDefault();
                return;
            }

            setRateLimiter(now);

        }

        if (disabled) {
            if (typeof disabled === 'function')
                disabled(ev);
            else
                ev.preventDefault();
            return;
        }

        if (onClick)
            onClick(ev);
    };

    return (
        <div className={ cn(style.btnbox, className, {
            [style.disabled]: disabled,
        }) }>
            <button type={ type } className={ cn(style.btn, {
                [style.disabled]: disabled,
            }) } onClick={ clickHandler }>
                { children || value || 'no-value' }
            </button>
        </div>
    );
}
