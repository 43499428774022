import { Action } from './Action';
import { Auther } from '../Auther';
import { GET_CARBON_BANKS_LIST } from '../Routes/Routes';

export interface ICarbonBank {
    name: string,
    url: string,
    amount: number,
}

export class GetCarbonBanksList extends Action<Array<ICarbonBank>> {

    constructor(bridge: Auther) {
        super(GET_CARBON_BANKS_LIST, bridge);
        this.actionConf.authorize = false;
    }

}