import { Action } from '../Action';
import { Auther } from '../../Auther';
import { SHOP_DELETE_PAYMENT } from '../../Routes/Routes';
import { RouteBuilder } from '../../Routes/RouteBuilder';

export interface CancelResult {
    message: string
}

export class CancelPayment extends Action<CancelResult> {

    private readonly paymentId: string;

    constructor(bridge: Auther, paymentId: string) {
        super(SHOP_DELETE_PAYMENT, bridge);
        this.paymentId = paymentId;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(this.paymentId);
    }

}