import { Action } from '../Action';
import { Auther } from '../../Auther';
import { LEAVE_MOD_QUEUE } from '../../Routes/Routes';

export interface LeaveProps {
    placeId: string;
}

export class Leave extends Action<void, LeaveProps> {

    constructor(auther: Auther, props: LeaveProps) {
        super(LEAVE_MOD_QUEUE, auther, props);
    }

}