import { Action } from './Action';
import { CREATE_CHAIN_LINK } from '../Routes/Routes';
import { Auther } from '../Auther';
import { RouteBuilder } from '../Routes/RouteBuilder';

export class CreateChainLink extends Action<void> {

    private readonly chainName: string;
    private readonly data: any | undefined;

    constructor(bridge: Auther, chainName: string, data? :any) {
        super(CREATE_CHAIN_LINK, bridge);
        this.chainName = chainName;
        this.data = data;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route
            .compileRoute(this.chainName);
    }

    getBody(): any {
        return this.data;
    }


}