import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

export function useOutsideClick(
    el: React.RefObject<HTMLDivElement>,
    initialState: boolean,
    // eslint-disable-next-line no-unused-vars
    onLeave?: () => void,
) : [ boolean, Dispatch<SetStateAction<boolean>>] {
    const [ isActive, setIsActive ] = useState(initialState);


    useEffect(() => {
        const onClick = (ev: MouseEvent) => {
            if (el.current !== null)
                // @ts-ignore
                if (!el.current.contains(ev.target)) {
                    setIsActive(!isActive);
                    if (onLeave) onLeave();
                }
        };

        if (isActive)
            window.addEventListener('click', onClick);

        return () => {
            window.removeEventListener('click', onClick);
        };

    }, [ isActive, el ]);

    return [ isActive, setIsActive ];
}