
export class TransactionUtils {

    public static parse(payload: { state: string }) : {
        percent: number,
        state: string,
    } {

        if (payload.state === 'FAILED' || payload.state === 'DROPPED'
            || payload.state === 'CANCELED_USER' || payload.state === 'CANCELED_PLATFORM')
            return {
                percent: -1,
                state: 'Transaction failed.',
            };

        switch (payload.state) {

        case 'PENDING':
            return {
                percent: 0,
                state: 'Waiting for Authorization',
            };

        case 'TP_PROCESSING':
            return {
                percent: 10,
                state: 'Signing Transaction',
            };

        case 'BROADCAST':
            return {
                percent: 20,
                state: 'Blockchain Processing',
            };

        case 'EXECUTED':
            return {
                state: 'Transaction Complete',
                percent: 100,
            };

        }

        console.warn('Unknown transaction state: ' + payload.state);

        return {
            state: 'Unknown',
            percent: 0,
        };
    }

    public static mapType(type: string) {
        if (type.toLowerCase() === 'offer') return 'GIFT';
        return type;
    }

}