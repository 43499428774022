import { Action } from '../Action';
import { ITemplate } from './index';
import { Auther } from '../../Auther';
import { GET_MOD_TEMPLATES } from '../../Routes/Routes';


export class GetTemplates extends Action<Array<ITemplate>> {

    constructor(bridger: Auther) {
        super(GET_MOD_TEMPLATES, bridger);
    }

}