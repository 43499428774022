import { Action } from '../Action';
import { Auther } from '../../Auther';
import { RouteBuilder } from '../../Routes/RouteBuilder';
import { GET_TOKEN_TOP3_VIDEOS } from '../../Routes/Routes';
import { TokenUtilityVideo } from '../../EntityTypes/TokenUtilityVideo';

export interface GetTop3VideosParams {
    tokenId: string
}

export class GetTop3Videos extends Action<{ videos: Array<TokenUtilityVideo> }> {

  private readonly params: GetTop3VideosParams;

  constructor(bridge: Auther, params: GetTop3VideosParams) {
      super(GET_TOKEN_TOP3_VIDEOS, bridge);
      this.params = params;
  }

  getCompiledRoute(): RouteBuilder {
      return this.route.compileRoute(
          this.params.tokenId,
      );
  }

}