import styles from './HeaderUserDropdown.module.scss';
import Link from 'next/link';
import { ForwardedRef, forwardRef } from 'react';
import { useAuth } from '../../../contexts/AuthContext';
import cn from 'classnames';

interface Props {
    className?: string
}

const HeaderUserDropdown = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const { logout } = useAuth();

    return (
        <div className={ cn(styles.dropdownMenu, props.className) } ref={ ref } >
            <div className={ styles.dropdownMenu__content }>

                <div><Link href='/user/inventory'>NFT Collection</Link></div>
                <div><Link href='/user/history'>NFT Transactions</Link></div>
                <div><Link href='/user/carbon'>Carbon Purchases</Link></div>
                <div><Link href='/user/account'>Account Details</Link></div>
                <div
                    className={ styles.dropdownMenu__content_logout }>
                    <a
                        onClick={ ev => {
                            ev.preventDefault();
                            if (logout)
                                logout(false);
                        } }
                    >
                        Log out
                    </a>
                </div>
            </div>
        </div>
    );
});

HeaderUserDropdown.displayName = 'HeaderUserDropdown';

export default HeaderUserDropdown;