import { Auther } from '../Auther';
import { Action } from './Action';
import { GET_LINKED_CHAINS } from '../Routes/Routes';

interface LinkedChain {
    chainName: string,
    address: string,
}

export class GetLinkedChains extends Action<LinkedChain[]> {

    constructor(bridge: Auther) {
        super(GET_LINKED_CHAINS, bridge);
    }

}