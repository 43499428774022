import React, { ForwardedRef, forwardRef } from 'react';
import styles from './ShoppingCart.module.scss';
import MetaButton from '../MetaButton/MetaButton';
import Link from 'next/link';
import { ParseCarbon } from '../../../utils/ParseCarbon';
import { useCarbon } from '../../../contexts/CarbonContext';

interface CarbonDisplayProps {
    onChoose?: () => void,
}

export const CarbonDisplay = forwardRef<HTMLDivElement, CarbonDisplayProps>((props, ref) => Component(props, ref));
CarbonDisplay.displayName = 'CarbonDisplay';

function Component(props: CarbonDisplayProps, ref: ForwardedRef<HTMLDivElement>) {

    const { amount: carbon } = useCarbon();

    const handleChoose = () => {
        if (props.onChoose)
            props.onChoose();
    };

    return (
        <div className={ styles.container } ref={ ref }>
            <div className={ styles.shoppingListContainer }>
                <h6 className={ styles.title }>Carbon Balance</h6>
                <section/>
                <div className={ styles.totalContainer }>
                    <span className={ styles.fundsTitle }>Available Funds</span>
                    <br />
                    <span className={ styles.funds }>{ParseCarbon.parse(carbon)} of CARBON </span>
                </div>
            </div>
            <div className={ styles.paymentInfo }>
                <Link href='/user/carbon'>
                    <a
                        className={ styles.paymentInfo__simpleLink }
                        onClick={ handleChoose }
                    >
                        Past Purchases
                    </a>
                </Link>
                <MetaButton
                    className={ styles.button }
                >
                    <Link href='/carbon/shop'>
                        <a onClick={ handleChoose }>
                            TOP UP FUNDS
                        </a>
                    </Link>
                </MetaButton>
            </div>
        </div>
    );
}
