import * as Sentry from '@sentry/nextjs';
import { MetaAPI } from './API/MetaAPI';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const disable = process.env.LOCAL_TEST || process.env.NEXT_PUBLIC_SENTRY_DISABLE;

if (!disable)
    Sentry.init({
        dsn: SENTRY_DSN,
        tracesSampleRate: 0.2,
        beforeSend: event => {
            if (MetaAPI.getInstance().auther.tokenManager.isLoggedIn()) {
                const { userId, username } = MetaAPI.getInstance().auther.tokenManager.getToken();
                event.user = {
                    ...event.user,
                    username,
                    id: userId.toString(),
                };
            }
            return event;
        },
    });
