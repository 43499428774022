import { Action } from '../Action';
import { Auther } from '../../Auther';
import { PING_MOD } from '../../Routes/Routes';

export interface IPing {
    userId: number,
    placeId: string,
    placeNumber: number,
    isMinter: boolean,
    delta: number,
}

interface PingRequest {
    placeId: string,
}

export class Ping extends Action<IPing, PingRequest> {

    constructor(bridge: Auther, req: PingRequest) {
        super(PING_MOD, bridge, req);
    }

}