import { CreatePayment, PaymentGateway } from './CreatePayment';
import { Auther } from '../../Auther';
import { CapturePayment } from './CapturePayment';
import { GetCreditCards } from './GetCreditCards';
import { CreateCreditCard, CreateCreditCardProps } from './CreateCreditCard';
import { GetCreditCard } from './GetCreditCard';
import { GetShopItems } from '../GetShopItems';
import { GetPayment } from './GetPayment';
import { GetCirclePubKey } from './GetCirclePubKey';
import { DeleteCreditCard } from './DeleteCreditCard';
import { ContactDetails } from '../../EntityTypes/SensitiveDetails';
import { GetAllPayments } from './GetAllPayments';
import { CancelPayment } from './CancelPayment';


export class PaymentActions {

    private readonly auther: Auther;

    constructor(auther: Auther) {
        this.auther = auther;
    }

    public createPayment(gateway: PaymentGateway, itemId: string, contactDetails: ContactDetails, amount: number, extraProps?: Record<string, unknown>) {
        return new CreatePayment(this.auther, gateway, itemId, contactDetails, amount, extraProps);
    }

    public capturePayment(paymentId: string, drop?: boolean) {
        return new CapturePayment(this.auther, paymentId, drop);
    }

    public cancelPayment(paymentId: string) {
        return new CancelPayment(this.auther, paymentId);
    }

    public getCreditCard(cardId: string) {
        return new GetCreditCard(this.auther, cardId);
    }

    public getAllPayments() {
        return new GetAllPayments(this.auther);
    }

    public getPayment(paymentId: string) {
        return new GetPayment(this.auther, paymentId);
    }

    public getShopItems() {
        return new GetShopItems(this.auther);
    }

    public getCirclePubKey() {
        return new GetCirclePubKey(this.auther);
    }

    public getCreditCards() {
        return new GetCreditCards(this.auther);
    }

    public createCreditCard(props: CreateCreditCardProps) {
        return new CreateCreditCard(this.auther, props);
    }

    public deleteCreditCard(cardId: string) {
        return new DeleteCreditCard(this.auther, cardId);
    }
}