import { Auther } from '../Auther';
import { ADD_GAME_REQUEST } from '../Routes/Routes';
import { Action } from './Action';

export interface AddGameRequestParams {
  name: string;
  game: string;
  email: string;
  phone: string;
  token: string;
  genre: string;
  website: string;
  company: string;
  gameplay: string;
  description: string;
}

export class AddGameRequest extends Action<{ message: string }, AddGameRequestParams> {

    constructor(bridge: Auther, params: AddGameRequestParams) {
        super(ADD_GAME_REQUEST, bridge, params);
    }

}