import { Auther } from '../../Auther';
import { GET_PENDING_MINT_QUEUES } from '../../Routes/Routes';
import { Action } from '../Action';

export interface PendingQueue {
    queueId: string,
    id: string,
    state: string,
    type: string,
    tokenId?: string,
    indexes?: Array<string>
}

export class GetPendingQueues extends Action<Array<PendingQueue>> {

    constructor(auther: Auther) {
        super(GET_PENDING_MINT_QUEUES, auther);
    }

}
