import { useEffect, useState } from 'react';
import { EventHandler } from '../API/EventHandler/EventHandler';
import { useIsAuth } from '../contexts/AuthContext';

export function useEventSource() {

    const [ listening, setListening ] = useState(false);
    const [ eventHandler, setEventHandler ] = useState<EventHandler>();

    const isAuth = useIsAuth();

    useEffect(() => {
        if (!listening) {
            const eventHandler = EventHandler.newEventHandler(!isAuth);
            setListening(true);
            setEventHandler(eventHandler);
        }
    }, [ listening, isAuth ]);

    return {
        listening,
        eventHandler,
    };

}