import { useRouter } from 'next/router';
import { useEffect } from 'react';

export function useRouteChange(action: () => void) {
    const router = useRouter();

    useEffect(() => {
        router.events.on('routeChangeStart', action);

        return () => {
            router.events.off('routeChangeStart', action);
        };
    }, [ action, router.events ]);
}