import { Action } from './Action';
import { Metadata } from '../EntityTypes/Wallet';
import { Auther } from '../Auther';
import { TOKEN_SIGN } from '../Routes/Routes';
import { RouteBuilder } from '../Routes/RouteBuilder';

interface UpdateTokenPropertyParams {
    tokenId: string,
    tokenIndex: string,
}

export class SignToken extends Action<Metadata & { tokenId: string; tokenIndex: string }, { content: any }> {

    private readonly params: UpdateTokenPropertyParams;

    constructor(bridge: Auther, params: UpdateTokenPropertyParams) {
        super(TOKEN_SIGN, bridge);
        this.params = params;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(
            this.params.tokenId,
            this.params.tokenIndex,
        );
    }

}