import React from 'react';
import style from './Footer.module.scss';
import Logo from '../../../public/logos/main.png';
import Image from 'next/image';
import Link from 'next/link';
import { Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord, faTelegram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

function Footer() {
    return (
        <section className={ `${ style.footer }` }>
            <div className={ 'containerLength' }>
                <Row>
                    <Col xs={ 12 } sm={ 12 } md={ 12 } lg={ 6 } xl={ 6 }>
                        <div className={ style.logo }>
                            <Link href={ '/' } passHref>
                                <a>
                                    <Image
                                        src={ Logo }
                                        alt='MyMetaverse Logo'
                                        layout={ 'fill' }
                                    />
                                </a>
                            </Link>
                        </div>
                    </Col>
                    <Col className={ style.footerSocialIcons } xs={ 12 } sm={ 12 } md={ 12 } lg={ 0 } xl={ 0 }>
                        <div className={ style.socialSquare }>
                            <div>
                                <a href='https://twitter.com/mymetaverse' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={ faTwitter } /></a>
                            </div>
                            <div>
                                <a href='https://www.youtube.com/mymetaverse' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={ faYoutube } /></a>
                            </div>
                            <div>
                                <a href='https://discord.gg/DJTzX83QnJ' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={ faDiscord } /></a>
                            </div>
                            <div>
                                <a href='https://t.me/MyMetaverse' target='_blank' rel='noreferrer'><FontAwesomeIcon icon={ faTelegram } /></a>
                            </div>
                        </div>
                    </Col>
                    <Col className={ style.footerLinks } xs={ 24 } sm={ 24 } md={ 24 } lg={ 6 } xl={ 6 }>
                        <div className={ style.link }>
                            <Link href='/privacy'>
                                Privacy Policy
                            </Link>
                        </div>
                        <div className={ style.link }>
                            <Link href='/terms'>
                                Terms of Service
                            </Link>
                        </div>
                    </Col>
                    <Col className={ style.footerCopy } xs={ 24 } sm={ 24 } md={ 24 } lg={ 12 } xl={ 12 }>
                        <div className={ style.copy }>
                            <span>
                                &copy; Parallel Planes PTY LTD 2021. All Rights Reserved
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
}

export default Footer;
