import { Action } from '../Action';
import { ShopItem } from '../GetShopItems';
import { SHOP_GET_ALL_PAYMENTS } from '../../Routes/Routes';
import { Auther } from '../../Auther';

export interface CompletePayment {
    id: string,
    method: string,
    status: string,
    statusDescription: string,
    createdAt: number,
    totalCost: number,
    items: Array<{
        amount: number,
        item: ShopItem
    }>
}

export class GetAllPayments extends Action<Array<CompletePayment>> {

    constructor(bridge: Auther) {
        super(SHOP_GET_ALL_PAYMENTS, bridge);
    }

}