import { Action } from '../Action';
import { Auther } from '../../Auther';
import { SHOP_CAPTURE_PAYMENT } from '../../Routes/Routes';
import { RouteBuilder } from '../../Routes/RouteBuilder';

export interface PaymentDetails {
    message: string
}

export class CapturePayment extends Action<PaymentDetails, { drop?: boolean }> {

    private readonly paymentId: string;

    constructor(bridge: Auther, paymentId: string, drop?: boolean) {
        super(SHOP_CAPTURE_PAYMENT, bridge, { drop });
        this.paymentId = paymentId;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(this.paymentId);
    }

}