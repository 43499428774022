import { Auther } from './Auther';
import { RequestSensitiveDetails } from './Actions/RequestSensitiveDetails';
import { SensitiveDetails } from './EntityTypes/SensitiveDetails';
import { UpdateSensitiveDetails } from './Actions/UpdateSensitiveDetails';
import { TokenManager } from './TokenManager';
import { GetAdoptionDetails } from './Actions/GetAdoptionDetails';
import { ProcessLinkingLink, ProcessLinkingLinkBody } from './Actions/ProcessLinkingLink';
import { GetLinkingLinkIntrospection, GetLinkingLinkIntrospectionProps } from './Actions/GetLinkingLinkIntrospection';
import { GetWallet } from './Actions/GetWallet';
import { GetMetaCitizen } from './Actions/GetMetaCitizen';
import { GetAdoptions } from './Actions/GetAdoptions';
import { PickMetaCitizen } from './Actions/PickMetaCitizen';
import { GetLinkedApps } from './Actions/GetLinkedApps';
import { UpdateTokenDetails, UpdateTokenDetailsParams } from './Actions/UpdateTokenDetails';
import { AddGameRequest, AddGameRequestParams } from './Actions/AddGameRequest';
import { GetHistory } from './Actions/GetHistory';
import { MintActions } from './Actions/mint/MintActions';
import { PaymentActions } from './Actions/PaymentEndpoints/PaymentActions';
import { GetCarbon } from './Actions/GetCarbon';
import { AuthEventListener } from './EventHandler/AuthEventListener';
import { GetHistoryHydrate, GetHistoryHydrateProps } from './Actions/GetHistoryHydrate';
import { GetCarbonBanks } from './Actions/GetCarbonBanks';
import { GetCarbonBanksList } from './Actions/GetCarbonBanksList';
import { MintOnDemandActions } from './Actions/MintOnDemand';
import { SignToken } from './Actions/SignToken';
import { GetTokenVideos } from './Actions/Utility/GetTokenVideos';
import { VoteForUtilityVideo, VoteForUtilityVideoParams } from './Actions/Utility/VoteForUtilityVideo';
import { AddTokenVideo, AddTokenVideoParams } from './Actions/Utility/AddTokenVideo';
import { GetTop3Videos } from './Actions/Utility/GetTop3Videos';
import { PlayToEarnActions } from './Actions/P2E';
import { EditUtilityVideo, EditUtilityVideoParams } from './Actions/Utility/EditUtilityVideo';
import { DeleteUtilityVideo } from './Actions/Utility/DeleteUtilityVideo';
import { GetChainLink } from './Actions/GetChainLink';
import { CreateChainLink } from './Actions/CreateChainLink';
import { GetLinkedChains } from './Actions/GetLinkedChains';
import { ContactRequest, ContactRequestParams } from './Actions/ContactRequest';

export class MetaAPI {

    static instance: MetaAPI;

    public readonly auther: Auther;

    protected readonly mintActions: MintActions;

    private readonly paymentAdapter: PaymentActions;

    private readonly mintOnDemandAdapter: MintOnDemandActions;

    private readonly play2EarnAdapter: PlayToEarnActions;

    constructor(auther: Auther) {
        this.auther = auther;
        this.mintActions = new MintActions(auther);
        this.paymentAdapter = new PaymentActions(this.auther);
        this.mintOnDemandAdapter = new MintOnDemandActions(this.auther);
        this.play2EarnAdapter = new PlayToEarnActions(this.auther);
    }

    public requestSensitiveDetails() : RequestSensitiveDetails {
        return new RequestSensitiveDetails(this.auther);
    }

    public updateSensitiveDetails(props: Omit<SensitiveDetails, 'mfa' | 'verified' | 'userId' | 'email'>) : UpdateSensitiveDetails {
        return new UpdateSensitiveDetails(this.auther, props);
    }

    public getAdoptionDetails(id: string) : GetAdoptionDetails {
        return new GetAdoptionDetails(this.auther, id);
    }

    public processLinkingLink(props: ProcessLinkingLinkBody) {
        return new ProcessLinkingLink(this.auther, props);
    }

    public getLinkingLinkIntrospection(props: GetLinkingLinkIntrospectionProps) {
        return new GetLinkingLinkIntrospection(this.auther, props.linkingId);
    }

    public getWallet() {
        return new GetWallet(this.auther);
    }

    public getMetaCitizen(index?: string) {
        return new GetMetaCitizen(this.auther, index);
    }

    public getAdoptions() {
        return new GetAdoptions(this.auther);
    }

    public pickMetaCitizen(index: string) {
        return new PickMetaCitizen(this.auther, index);
    }

    public getLinkedApps() {
        return new GetLinkedApps(this.auther);
    }

    public getHistory() {
        return new GetHistory(this.auther);
    }

    public getHistoryHydrate(swapId: string, props: GetHistoryHydrateProps) {
        return new GetHistoryHydrate(this.auther, swapId, props);
    }

    public editTokenDetails(tokenId: string, params: UpdateTokenDetailsParams) {
        return new UpdateTokenDetails(this.auther, tokenId, params);
    }

    public addGameRequest(params: AddGameRequestParams) {
        return new AddGameRequest(this.auther, params);
    }

    public contactRequest(params: ContactRequestParams) {
        return new ContactRequest(this.auther, params);
    }

    public getMinter() {
        return this.mintActions;
    }

    public getPayments() {
        return this.paymentAdapter;
    }

    public getCarbon() {
        return new GetCarbon(this.auther);
    }

    public getCarbonBanks() {
        return new GetCarbonBanks(this.auther);
    }

    public getCarbonBanksList() {
        return new GetCarbonBanksList(this.auther);
    }

    public authEventSource(clientId: string) {
        return new AuthEventListener(this.auther, clientId);
    }

    public getMintOnDemand() {
        return this.mintOnDemandAdapter;
    }

    public getChainLink(chainName: string) {
        return new GetChainLink(this.auther, chainName);
    }

    public createChainLink(chainName: string, data?: any) {
        return new CreateChainLink(this.auther, chainName, data);
    }

    public getLinkedChains() {
        return new GetLinkedChains(this.auther);
    }

    public getPlayToEarn() {
        return this.play2EarnAdapter;
    }

    public signToken(tokenId: string, tokenIndex: string) {
        return new SignToken(this.auther, {
            tokenId,
            tokenIndex,
        });
    }

    public addTokenUtilityVideo(tokenId: string, params: AddTokenVideoParams) {
        return new AddTokenVideo(this.auther, tokenId, params);
    }

    public editUtilityVideo(tokenId: string, videoId: string, params: EditUtilityVideoParams) {
        return new EditUtilityVideo(this.auther, tokenId, videoId, params);
    }

    public deleteUtilityVideo(tokenId: string, videoId: string) {
        return new DeleteUtilityVideo(this.auther, {
            tokenId,
            videoId,
        });
    }

    public getTokenVideos(tokenId: string, top3: boolean) {
        if (top3)
            return new GetTop3Videos(this.auther, {
                tokenId,
            });

        return new GetTokenVideos(this.auther, {
            tokenId,
        });
    }

    public voteForVideo(tokenId: string, params: VoteForUtilityVideoParams) {
        return new VoteForUtilityVideo(this.auther, tokenId, params);
    }

    private static createDefault() : MetaAPI {
        return new MetaAPI(new Auther(new TokenManager()));
    }

    public static createRaw() : MetaAPI {
        // @ts-ignore
        return new MetaAPI();
    }

    public static getInstance() : MetaAPI {
        if (!this.instance)
            this.instance = this.createDefault();
        return this.instance;
    }
}