import { Action } from '../Action';
import { Auther } from '../../Auther';
import { RouteBuilder } from '../../Routes/RouteBuilder';
import { DELETE_TOKEN_UTILITY_VIDEO } from '../../Routes/Routes';
import { TokenUtilityVideo } from '../../EntityTypes/TokenUtilityVideo';

export interface DeleteUtilityVideoParams {
    tokenId: string;
    videoId: string;
}

export class DeleteUtilityVideo extends Action<TokenUtilityVideo> {

  private readonly params: DeleteUtilityVideoParams;

  constructor(bridge: Auther, params: DeleteUtilityVideoParams) {
      super(DELETE_TOKEN_UTILITY_VIDEO, bridge);
      this.params = params;
  }

  getCompiledRoute(): RouteBuilder {
      return this.route.compileRoute(
          this.params.tokenId,
          this.params.videoId,
      );
  }

}