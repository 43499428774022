import { Action } from '../Action';
import { Auther } from '../../Auther';
import { CreditCard } from '../../EntityTypes/CreditCard';
import { SHOP_CIRCLE_CREATE_CARD } from '../../Routes/Routes';

export interface CreateCreditCardProps {
    keyId: string,
    encryptedData: string,

    expMonth: number,
    expYear: number,

    name: string,
    lastName: string,
    streetAddress: string,
    streetAddress2: string,
    city: string,
    state: string,
    country: string,
    postalCode: string,
}

export class CreateCreditCard extends Action<CreditCard, CreateCreditCardProps> {

    constructor(bridge: Auther, props: CreateCreditCardProps) {
        super(SHOP_CIRCLE_CREATE_CARD, bridge, props);
    }

}