import { Action } from '../Actions/Action';
import { Auther } from '../Auther';
import { EVENTSOURCE_AUTH } from '../Routes/Routes';


export class AuthEventListener extends Action<{ message: string }, { clientId: string }> {

    private readonly clientId: string;

    constructor(auther: Auther, clientId: string) {
        super(EVENTSOURCE_AUTH, auther);
        this.clientId = clientId;
    }

    getBody(): {
        clientId: string
        } {
        return {
            clientId: this.clientId,
        };
    }

}