import { Action } from './Action';
import { PICK_METACITIZEN } from '../Routes/Routes';
import { Auther } from '../Auther';

interface PickMetaCitizenProps {
    index: string,
}

export class PickMetaCitizen extends Action<{
    index: string,
    userId: number
}, PickMetaCitizenProps> {

    private readonly index: string;

    constructor(auther: Auther, index: string) {
        super(PICK_METACITIZEN, auther, { index });
    }

}