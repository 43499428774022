import { faCircleCheck, faSpinner, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useIsAuth } from '../AuthContext';
import styles from './MintContext.module.scss';
import { useEventSource } from '../../hooks/useEventSource';
import { TransactionEvent } from '../../API/EntityTypes/Mint';
import { PendingQueue } from '../../API/Actions/mint/GetPendingQueues';
import { TransactionUtils } from '../../utils/TransactionUtils';
import { Row } from 'antd';
import Modal from '../../components/common/Modal/Modal';
import cn from 'classnames';

type Txn = { id: string, state: string, type: string };

interface IMintContext {
    pending: Array<Txn>,
    // eslint-disable-next-line no-unused-vars
    addPending(pendingQueue: PendingQueue, show?: boolean): void,
}

const MintContext = React.createContext<Partial<IMintContext>>({
    pending: [],
});

export const MintProvider = ({ children }: { children: React.ReactNode }) => {

    const isAuth = useIsAuth();
    const { eventHandler } = useEventSource();

    const [ pending, setPending ] = useState<Array<Txn>>([]);

    const addPending = (txn: Txn) => {
        if (pending) {
            const exists = pending.some(p => p.id === txn.id);

            if (!exists)
                setPending([ ...pending, txn ]);
            else {
                const removedOld = pending.filter(value => value.id !== txn.id);
                setPending([ ...removedOld, txn ]);
            }

        }
    };


    useEffect(() => {
        if (!eventHandler)
            return;

        const func = (payload: TransactionEvent) => {
            addPending({
                id: payload.id,
                state: payload.state,
                type: payload.transactionType,
            });
        };

        const { off } = eventHandler.on<TransactionEvent>(/^mint$/, func);

        return () => off();
    }, [ addPending, eventHandler, pending ]);

    return (
        <MintContext.Provider
            value={ {
                pending,
                addPending,
            } }
        >
            {
                isAuth
                    && <div className={ styles.mints }>
                        {
                            (pending || []).map((pending, key) => {

                                const translation = TransactionUtils.parse({
                                    state: pending.state,
                                });

                                return <div
                                    className={ styles.mints__mint }
                                    key={ key }
                                    onClick={ () => {
                                        if (pending.state === 'EXECUTED' || translation.percent === -1)
                                            setPending(pendingList => pendingList.filter(value => value.id !== pending.id));
                                    } }
                                >
                                    <div className={ styles.mints__mint__progress }>
                                        {
                                            translation.percent === -1
                                                ? <FontAwesomeIcon
                                                    icon={ faTimesCircle }
                                                    className={ cn({
                                                        [styles.error]: translation.percent === -1,
                                                    }) }
                                                />
                                                : translation.percent === 100
                                                    ? <FontAwesomeIcon
                                                        icon={ faCircleCheck }
                                                        className={ cn({
                                                            [styles.success]: translation.percent === 100,
                                                        }) }
                                                    />
                                                    : <FontAwesomeIcon icon={ faSpinner } spin />
                                        }
                                    </div>
                                    <div>
                                        <Row>
                                            { translation.state }
                                        </Row>
                                        <Row className={ styles.mints__mint__id } >{ pending.id }</Row>
                                    </div>

                                </div>;
                            },

                            )
                        }
                    </div>
            }
            {children}
        </MintContext.Provider>
    );

};

export function useMint() {
    return React.useContext(MintContext);
}


