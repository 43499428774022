import { Action } from '../Action';
import { Auther } from '../../Auther';
import { CreditCard } from '../../EntityTypes/CreditCard';
import { SHOP_CIRCLE_CREDIT_CARD } from '../../Routes/Routes';
import { RouteBuilder } from '../../Routes/RouteBuilder';


export class GetCreditCard extends Action<CreditCard> {

    private readonly cardId;

    constructor(bridge: Auther, cardId: string) {
        super(SHOP_CIRCLE_CREDIT_CARD, bridge);
        this.cardId = cardId;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(this.cardId);
    }
}