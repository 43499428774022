import React from 'react';

import styles from './Layout.module.scss';
import HeadSeo from '../../HeadSeo/HeadSeo';
import Footer from '../layer/Footer/Footer';
import HeaderWrapper from '../layer/HeaderWrapper/HeaderWrapper';

export default function Layout({ children }: {
    children: React.ReactNode
}) {

    return (
        <main>
            <HeadSeo
                type="website"
                url="https://mymetaverse.io/"
                image="https://mymetaverse.io/meta_thumb.png"
                title="The Premiere NFT Game and Metaverse Network"
                description="MyMetaverse is the world's premiere NFT game and metaverse network providing a gaming experience for players and creators around the world."
            />

            <HeaderWrapper />

            <div className={ styles.content }>
                { children }

                <div id="app-modals"></div>
            </div>

            <Footer />

        </main>
    );

}