import axios from 'axios';
import { TokenResponse } from './ApiTypes';
import { TokenManager } from './TokenManager';
import { AuthorizeProps } from './EntityTypes/AuthorizeProps';
import { toast } from 'react-toastify';

export const API_URL = process.env.NEXT_PUBLIC_REQUEST_SERVER;
const AUTH_URL = process.env.NEXT_PUBLIC_AUTH_SERVER;

export class Auther {
    public readonly tokenManager: TokenManager;

    private lastRefresh?: number;
    private refreshing = false;

    constructor(tokenManager: TokenManager) {
        this.tokenManager = tokenManager;
    }

    // TODO - We should logout and invalid token here.
    public async logout() {
        throw new Error('Not implemented');
    }

    public refreshTokenWithLock(force = false) {
        if (this.refreshing)
            return Promise.reject('Already refreshing');

        this.refreshing = true;

        return this.refreshTokenReview(force)
            .finally(() => {
                this.refreshing = false;
            });
    }

    public async refreshTokenReview(force = false) : Promise<void> {
        if ((force || !this.tokenManager.tokenIsValid()) && (!this.lastRefresh || Date.now() - this.lastRefresh > 5000)) {
            console.info('Refreshing Token.');
            this.lastRefresh = Date.now();

            const params = new URLSearchParams();
            params.append('grant_type', 'refresh_token');
            params.append('refresh_token', this.tokenManager.getToken().refreshToken);

            try {
                const result = await axios.post(AUTH_URL + '/token', params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                });

                const data = result.data as TokenResponse;

                this.tokenManager.saveToken({
                    ...this.tokenManager.getToken(),
                    refreshToken: data.refresh_token,
                    accessToken: data.access_token,
                    refreshIn: data.expires_in,
                });

                console.info('Token refreshed.');
            } catch (err) {
                if (axios.isAxiosError(err))
                    if (err.response.status === 400 && err.response.data.error === 'invalid_request') {
                        this.tokenManager.logout();
                        toast.info('Your session has expired. Please login again.');

                        setTimeout(() => {
                            // We do this way so the token will try to validate again.
                            window.location.href = '/login';
                        }, 1000);
                    }
            } finally {
                this.lastRefresh = Date.now();
            }
        }
    }

    public getActiveToken() : string {
        return this.tokenManager.getToken().accessToken;
    }

    public async requestLogin(identifier: string, password: string, token: string): Promise<TokenResponse> {
        const res = await axios.post(AUTH_URL + '/login', {
            identifier,
            password,
            token,
        });

        return res.data as TokenResponse;
    }

    public async requestRegister(username: string, password: string, email: string, token: string) {
        return await axios.post(AUTH_URL + '/register', {
            username,
            email,
            password,
            token,
        });
    }

    public async verifyAccount(token: string) {
        return await axios.post(AUTH_URL + '/verify', { token });
    }

    public async authorizeApp(props: AuthorizeProps) {

        try {
            const res = await axios.post(AUTH_URL + '/authorize', {
                ...props,
            }, {
                headers: {
                    authorization: 'Bearer ' + await this.getActiveToken(),
                },
            });

            // -> Redirect with the valid information from auth server

            const response: {
                code: string,
                redirectUri: string,
                state: string
            } = {
                ...res.data,
            };

            if (response.state === props.state) {
                const url = new URL(response.redirectUri);
                url.searchParams.append('code', response.code);
                url.searchParams.append('state', props.state);
                toast('Redirecting to: ' + url.toString());

                window.location.href = url.toString();
            } else
                toast('Malformed state code.');
        } catch (err) {
            const url = new URL(props.redirect_uri);

            if (axios.isAxiosError(err) && err.response)
                Object.entries(err.response.data).forEach(([ k, v ]) => {
                    url.searchParams.append(k, String(v));
                });

            else if (err instanceof Error) {
                toast.error(err.message);
                url.searchParams.append('error', err.message);
            }

            window.location.href = url.toString();
        }

    }

    async enableTwoFactorAuthentication(password: string) {
        return await axios.post<{
            secret: string,
            uri: string,
            qr: string
        }>(AUTH_URL + '/twofactor/enable', {
            userId: this.tokenManager.getToken().userId,
            password,
        });
    }

    async verify2FAEnable(password: string, code: string) {
        return await axios.post<{
            message: string
        }>(AUTH_URL + '/twofactor/confirm', {
            password,
            code,
            userId: this.tokenManager.getToken().userId,
        });
    }

    async login2Fa(userId: number, code: string, password: string) {
        return await axios.post<Omit<TokenResponse, 'mfa'>>(AUTH_URL + '/twofactor/login', {
            userId,
            code,
            password,
        });
    }

    async disable2Fa(userId: number, password: string, code: string) {
        return await axios.post<{ message: string}>(AUTH_URL + '/twofactor/delete', {
            userId,
            password,
            code,
        });
    }

    async validatePassword(password: string, userId: number) {
        return await axios.post<{valid: boolean}>(AUTH_URL + '/password/validate', {
            password,
            userId,
        });
    }

    async resetPassword(email: string, token: string) {
        return await axios.post<{message: string}>(AUTH_URL + '/reset-password', {
            token,
            email,
        });
    }

    async resendVerificationEmail() {
        if (!this.tokenManager.isLoggedIn() && !this.tokenManager.tokenAvailableToUse()) {
            console.info('Please log in to use this route.');
            return;
        }

        return await axios.post<{ message: string }>(AUTH_URL + '/resend-verification', {}, {
            headers: {
                Authorization: 'Bearer ' + this.tokenManager.getToken().accessToken,
            },
        });
    }


    async resetPasswordProcess(code: string, newPassword: string, mfaCode?: string) {
        return await axios.post<{message: string}>(AUTH_URL + '/reset-password/process', {
            code,
            newPassword,
            mfaCode,
        });
    }

    async resetPasswordIntrospect(code: string) {
        return await axios.post<{ mfa: boolean }>(AUTH_URL + '/reset-password/introspect', {
            code,
        });
    }
}