import styles from '../../../styles/Page.module.scss';
import DivBg from '../../common/DivWithBackground/DivBg';
import Head from 'next/head';
import { Col, Row } from 'antd';
import React from 'react';
import Image from 'next/image';

export default function ComingSoonView({ display } : { display: string }) {

    return (
        <DivBg className={ `${ styles.wrapper }` } bgSrc={ '/page-bg.png' } imageProps={ {
            priority: true,
            quality: 100,
        } }>
            <div className={ styles.content }>
                <section className={ 'containerLength' }>
                    <Head>
                        <title>Join the Metaverse, start your adventure! | MyMetaverse</title>
                        <meta name="description" content="Join the metaverse and start your adventure in the world's premiere NFT game and metaverse network." />
                    </Head>
                    <Row className={ styles.box } gutter={ [ 32, 32 ] }>
                        <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 8 } xl={ 8 }>
                        </Col>
                        <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 8 } xl={ 8 }>
                            <div className={ styles.titlefade }>
                                <Image
                                    src={ '/title-fade.png' }
                                    alt={ 'mymetaverse' }
                                    layout='responsive'
                                    objectFit='contain'
                                    width="500px"
                                    height="15px"
                                />
                            </div>
                            <div className={ `${ styles.title } font32 up center` }>
                                { display }
                            </div>
                        </Col>
                        <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 8 } xl={ 8 }>
                        </Col>
                    </Row>
                </section>
            </div>
        </DivBg>
    );
}