import { IToken, TokenToSave } from './ApiTypes';

const varId = 'DFOU';

export class TokenManager {

    private tokenCache?: IToken;

    loadToken(): void {
        const store = localStorage.getItem(varId);
        if (!store)
            return;
        this.tokenCache = JSON.parse(store) as IToken;
    }

    getToken() : IToken {
        this.loadToken();

        if (!this.tokenCache)
            throw new Error('Invalid token information.');

        return this.tokenCache;
    }

    getUnsafeToken() : IToken | undefined {
        return this.tokenCache;
    }

    saveToken(tokenResponse: TokenToSave) : void {
        const currentTime = Date.now() / 1000;
        const result = {
            username: tokenResponse.username,
            userId: tokenResponse.userId,
            refreshToken: tokenResponse.refreshToken,
            accessToken: tokenResponse.accessToken,
            refreshAt: currentTime + tokenResponse.refreshIn,
        } as IToken;
        localStorage.setItem(varId, JSON.stringify(result));
        this.tokenCache = result;
    }

    tokenAvailableToUse() : boolean {
        return !!this.tokenCache;
    }

    tokenIsValid() : boolean {
        this.loadToken();

        if (!this.tokenCache)
            throw new Error('Invalid token information.');

        const currentTime = Date.now() / 1000;
        return this.tokenCache.refreshAt > currentTime;
    }

    public isLoggedIn(): boolean {
        return !!localStorage.getItem(varId);
    }

    public logout() : void {
        localStorage.removeItem(varId);
    }

}