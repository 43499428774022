import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './HeaderDropdown.module.scss';
import React, { useRef } from 'react';
import { useOutsideClick } from '../../../hooks/useOutsideClick';

export function HeaderDropdown({ label, children }: { label: string, children: React.ReactNode[] | React.ReactNode }) {

    const ref = useRef<HTMLDivElement>(null);
    const [ open, setOpen ] = useOutsideClick(ref, false);

    const toggleOpen = () => {
        setOpen(!open);
    };

    return (
        <div
            className={ styles.dropdown }
            onClick={ toggleOpen }
            role='link'
        >
            <div className={ styles.label }
                onClick={ toggleOpen }
            >
                <span>{ label }</span>
                {
                    open
                        ? <FontAwesomeIcon icon={ faChevronUp } />
                        : <FontAwesomeIcon icon={ faChevronDown } />
                }
            </div>
            {
                open
                && <div className={ styles.menu } ref={ ref }>
                    { children }
                </div>

            }
        </div>
    );
}