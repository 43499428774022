import { Action } from './Action';
import { GET_WALLET } from '../Routes/Routes';
import { Auther } from '../Auther';
import { Wallet } from '../EntityTypes/Wallet';

export class GetWallet extends Action<Wallet> {

    constructor(bridge: Auther) {
        super(GET_WALLET, bridge);
    }

    protected handleResponse(response: Wallet): Wallet {
        return {
            ...response,
            getAllItems: () => [ ...response.centralizedItems, ...Object.values(response.chains).flatMap(it => it.items) ],
        };
    }

}