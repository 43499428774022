import { Action } from '../Action';
import { SHOP_CIRCLE_CREDIT_CARDS } from '../../Routes/Routes';
import { Auther } from '../../Auther';
import { CreditCard } from '../../EntityTypes/CreditCard';


export class GetCreditCards extends Action<Array<CreditCard>> {
    constructor(bridge: Auther) {
        super(SHOP_CIRCLE_CREDIT_CARDS, bridge);
    }
}