import { Action } from './Action';
import { Auther } from '../Auther';
import { SENSITIVE_DETAILS_UPDATE } from '../Routes/Routes';
import { Message } from '../EntityTypes/Message';
import { SensitiveDetails } from '../EntityTypes/SensitiveDetails';

export class UpdateSensitiveDetails extends Action<Message> {

    private readonly props: Omit<SensitiveDetails, 'mfa' | 'verified' | 'userId' | 'email'>;

    constructor(bridge: Auther, props: Omit<SensitiveDetails, 'mfa' | 'verified' | 'userId' | 'email'>) {
        super(SENSITIVE_DETAILS_UPDATE, bridge);
        this.props = props;
    }

    getBody(): any {
        return {
            ...this.props,
            userId: this.bridge.tokenManager.getToken().userId,
        };
    }

}