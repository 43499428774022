import { Action } from './Action';
import { Auther } from '../Auther';
import { LINKING_LINK_INTROSPECTION } from '../Routes/Routes';
import { LinkingLinkIntrospection } from '../EntityTypes/LinkingLinkIntrospection';
import { RouteBuilder } from '../Routes/RouteBuilder';

export interface GetLinkingLinkIntrospectionProps {
    linkingId: string,
}

export class GetLinkingLinkIntrospection extends Action<LinkingLinkIntrospection> {

    private readonly linkingId: string;

    constructor(bridge: Auther, linkingId: string) {
        super(LINKING_LINK_INTROSPECTION, bridge);
        this.linkingId = linkingId;
    }

    public getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(this.linkingId);
    }

}