import { Action } from '../Action';
import { Auther } from '../../Auther';
import { RouteBuilder } from '../../Routes/RouteBuilder';
import { ADD_TOKEN_UTILITY_VIDEO } from '../../Routes/Routes';
import { TokenUtilityVideo } from '../../EntityTypes/TokenUtilityVideo';

export interface AddTokenVideoParams {
  title: string;
  videoLink: string;
  description: string;
}

export class AddTokenVideo extends Action<{ video: TokenUtilityVideo }, AddTokenVideoParams> {

    private readonly tokenId: string;

    constructor(bridge: Auther, tokenId: string, params: AddTokenVideoParams) {
        super(ADD_TOKEN_UTILITY_VIDEO, bridge, params);
        this.tokenId = tokenId;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(
            this.tokenId,
        );
    }

}