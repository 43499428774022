import { Action } from '../Action';
import { Auther } from '../../Auther';
import { RouteBuilder } from '../../Routes/RouteBuilder';
import { GET_CLIENT_LEADERBOARD } from '../../Routes/Routes';

export interface P2EClientLeaderboardBody {
  skip: number;
  limit: number;
}

export interface P2EClientLeaderboardItem {
    points: number;
    profileId: number;
    profileName: string;
}

export class GetP2EClientLeaderboard extends Action<Array<P2EClientLeaderboardItem>, P2EClientLeaderboardBody> {

    private readonly id: string;
    private readonly params: P2EClientLeaderboardBody;

    constructor(bridge: Auther, id: string, params: P2EClientLeaderboardBody) {
        super(GET_CLIENT_LEADERBOARD, bridge, params);
        this.id = id;
        this.params = params;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(this.id)
            .withParam('skip', `${ this.params.skip }`)
            .withParam('limit', `${ this.params.limit }`);
    }

    getBody(): any {
        return this.params;
    }
}