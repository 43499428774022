import { Action } from '../Action';
import { Auther } from '../../Auther';
import { SHOP_CIRCLE_DELETE_CREDIT_CARD } from '../../Routes/Routes';
import { Message } from '../../EntityTypes/Message';
import { RouteBuilder } from '../../Routes/RouteBuilder';


export class DeleteCreditCard extends Action<Message> {

    private readonly cardId;

    constructor(bridge: Auther, cardId: string) {
        super(SHOP_CIRCLE_DELETE_CREDIT_CARD, bridge);
        this.cardId = cardId;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(this.cardId);
    }
}