import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import styles from './Headerr.module.scss';
import React, { Fragment, useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import LogoImage from '../../../public/logos/main.png';
import LoginIcon from '../../../public/login_icon.svg';
import UsaFlag from '../../../public/flags/usa.png';
import SpainFlag from '../../../public/flags/spain.png';
import Link from 'next/link';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import HeaderUserDropdown from '../HeaderUserDropdown/HeaderUserDropdown';
import md5 from 'blueimp-md5';
import { ParseCarbon } from '../../../utils/ParseCarbon';
import { CarbonDisplay } from '../../common/ShoppingCart/CarbonDisplay';
import { useIsAuth } from '../../../contexts/AuthContext';
import { HeaderDropdown } from '../HeaderDropdown/HeaderDropdown';
import { Route } from '../HeaderWrapper/HeaderWrapper';
import cn from 'classnames';

export default function Header({ className, user, email, carbon, routes }: { className: string, user?: string, email?: string, carbon?: number, routes: Array<Route> }) {

    const dropdownContainer = useRef<HTMLDivElement>(null);
    const shopCartContainer = useRef<HTMLDivElement>(null);

    const [ isActiveDropdown, setIsActiveDropdown ] = useOutsideClick(dropdownContainer, false);
    const [ isActiveCountryDropdown, setIsActiveCountryDropdown ] = useState<boolean>(false);
    const [ isActiveShopCart, setIsActiveShopCart ] = useOutsideClick(shopCartContainer, false);

    const profilePhoto = useMemo(() => `https://gravatar.com/avatar/${ md5(email?.toLowerCase().trim() || 'asd') }?=65&d=identicon`, [ email ]);

    const isAuth = useIsAuth();

    const openDropdown = () => {
        setIsActiveDropdown(!isActiveDropdown);
    };

    const openCountryDropdown = () => {
        setIsActiveCountryDropdown(!isActiveCountryDropdown);
    };

    const routesFirstHalf = useMemo(() => routes.slice(0, Math.ceil(routes.length / 2)), [ routes ]);
    const routesSecondHalf = useMemo(() => routes.slice(Math.ceil(routes.length / 2)), [ routes ]);

    const renderRouteLink = (route: Route) => {
        const isExternal = route.link && (route.link.startsWith('http') || route.link.startsWith('https'));
        /* If is a sub route then just render the link without the styles */
        const Tag = !route.sub ? Fragment : 'div';

        if (isExternal)
            return (
                <a href={ route.link } target="_blank" rel="noopener noreferrer">
                    { route.name }
                </a>
            );

        return (
            <Tag
                key={ route.link }
                className={ cn(styles.menuLink, styles.pill) }
            >
                <Link href={ route.link }>{ route.name }</Link>
            </Tag>
        );
    };

    const renderMenu = (data: Array<Route>) => <>
        {
            data
                .filter(route => !!route.link)
                .map(renderRouteLink)
        }
        {
            data
                .filter(route => !!route.sub)
                .map(route =>
                    <HeaderDropdown
                        label={ route.name }
                        key={ 'label-' + route.name }
                    >
                        {
                            route.sub.map(renderRouteLink)
                        }
                    </HeaderDropdown>,
                )
        }
    </>;

    return (
        <>
            <div className={ 'containerLength' }>
                <div className={ `${ styles.header } ${ className }` }>
                    {
                        user
                            ? <div className={ styles.userBox }>
                                <div className={ styles.image }>
                                    <img
                                        src={ profilePhoto }
                                        width={ 45 }
                                        height={ 45 }
                                        alt={ `${ user } profile image.` }
                                    />
                                </div>
                                <div className={ styles.userData }
                                    role='link'
                                    onClick={ openDropdown }
                                    ref={ dropdownContainer } >
                                    <div className={ styles.userContainer }>
                                        <svg width="40" height="2" viewBox="0 0 40 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0H40L36 2H4L0 0Z" fill="white" />
                                        </svg>
                                        <div className={ styles.name }>
                                            {user}
                                        </div>
                                        <svg width="40" height="2" viewBox="0 0 40 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0H40L36 2H4L0 0Z" fill="white" />
                                        </svg>
                                    </div>
                                    <div className={ styles.arrow }>
                                        {
                                            isActiveDropdown
                                                ? <FontAwesomeIcon icon={ faChevronUp } />
                                                : <FontAwesomeIcon icon={ faChevronDown } />
                                        }
                                    </div>
                                </div>

                                {
                                    isActiveDropdown
                                        ? <HeaderUserDropdown className={ styles.userDropdown } />
                                        : []
                                }
                            </div>
                            : <div className={ styles.loginBox }>
                                <Image
                                    src={ LoginIcon }
                                    alt={ 'login.svg' }
                                    width={ 14.6 }
                                    height={ 16 }
                                />
                                <Link href={ '/login' }>Login</Link>
                            </div>
                    }
                    <div className={ styles.menuWrapper }>
                        <div className={ `${ styles.leftMenu } font14 ` }>
                            { renderMenu(routesFirstHalf) }
                        </div>

                        <div className={ styles.logo }>
                            <Link href={ '/' } passHref>
                                <a className={ styles.menuLink } >
                                    <Image
                                        src={ LogoImage }
                                        width={ 200 }
                                        height={ 117 }
                                        layout={ 'fixed' }
                                        alt='MyMetaverse Logo'
                                    />
                                </a>
                            </Link>
                        </div>

                        <div className={ `${ styles.rightMenu } font14` } data-cy='menu'>
                            { renderMenu(routesSecondHalf) }
                        </div>
                    </div>

                    <div className={ styles.optionsBox }>
                        <div className={ styles.lenguageBox }>
                            <div className={ styles.lenguageLabel } onClick={ openCountryDropdown }>
                                <Image
                                    src={ UsaFlag }
                                    width={ 24 }
                                    height={ 24 }
                                    alt={ 'lenguage.png' } />
                                <span>EN</span>
                                {
                                    isActiveCountryDropdown
                                        ? <FontAwesomeIcon icon={ faChevronUp } />
                                        : <FontAwesomeIcon icon={ faChevronDown } />
                                }
                            </div>
                            {
                                isActiveCountryDropdown
                                && <div className={ styles.lenguageList }>
                                    <div className={ styles.countryBox }>
                                        <Image
                                            src={ SpainFlag }
                                            width={ 24 }
                                            height={ 24 }
                                            alt={ 'lenguage.png' }
                                            className={ styles.flag } />
                                        <span>Spanish</span>
                                    </div>
                                </div>

                            }
                        </div>
                        <div className={ styles.cartBox }>
                            <div className={ styles.cartBox__box }>
                                <div className={ styles.cartBox__amount }>
                                    <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.39969 0C5.89747 0 6.29964 0.391836 6.29964 0.875V1.82273C7.16865 1.96438 7.96454 2.30754 8.6395 2.8C9.03886 3.08984 9.1176 3.63945 8.81949 4.025C8.49607 4.41328 7.9561 4.48984 7.55957 4.2C6.95772 3.75977 6.21246 3.5 5.39969 3.5C3.41137 3.5 1.7999 5.0668 1.7999 7C1.7999 8.9332 3.41137 10.5 5.39969 10.5C6.21246 10.5 6.95772 10.2402 7.55957 9.8C7.9561 9.51016 8.49607 9.58672 8.81949 9.975C9.1176 10.3605 9.03886 10.8855 8.6395 11.2C7.96454 11.6922 7.16865 12.0367 6.29964 12.1762V13.125C6.29964 13.609 5.89747 14 5.39969 14C4.90191 14 4.49974 13.609 4.49974 13.125V12.1762C1.94586 11.7605 0 9.60039 0 7C0 4.39961 1.94586 2.23918 4.49974 1.82273V0.875C4.49974 0.391836 4.90191 1.64063e-05 5.39969 1.64063e-05V0Z" fill="white" />
                                    </svg>
                                    {
                                        isAuth
                                            ? ParseCarbon.parse(carbon)
                                            : '0 KG'
                                    }
                                </div>
                                <Link href={ '/carbon/shop' } passHref>
                                    <a className={ styles.cartBox__topUp }>
                                        <span>TOP UP</span>
                                    </a>
                                </Link>
                            </div>

                            <div className={ styles.shoppingCartDropdownContainer }>
                                {isActiveShopCart
                                    && <CarbonDisplay
                                        ref={ shopCartContainer }
                                        onChoose={ () => {
                                            setIsActiveShopCart(false);
                                        } }
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
