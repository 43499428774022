import { Action } from '../Action';
import { ITemplate } from './index';
import { Auther } from '../../Auther';
import { GET_MOD_TEMPLATE } from '../../Routes/Routes';
import { RouteBuilder } from '../../Routes/RouteBuilder';


export class GetTemplate extends Action<ITemplate> {

    private readonly id: string;

    constructor(bridge: Auther, id: string) {
        super(GET_MOD_TEMPLATE, bridge);
        this.id = id;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(this.id);
    }

}