import { Action } from '../Action';
import { Auther } from '../../Auther';
import { STAKE_FOR_CLIENT } from '../../Routes/Routes';
import { RouteBuilder } from '../../Routes/RouteBuilder';

export class StakeForP2EClient extends Action<{ userId: number; targetId: string; }> {

    private readonly clientId: string;

    constructor(bridge: Auther, clientId: string) {
        super(STAKE_FOR_CLIENT, bridge);
        this.clientId = clientId;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(this.clientId);
    }

}