import { Action } from './Action';
import { Auther } from '../Auther';
import { GET_HISTORY } from '../Routes/Routes';
import { HistoryRecord } from '../EntityTypes/MintsHistory';

export class GetHistory extends Action<{ transfers: HistoryRecord[] }> {

    constructor(auther: Auther) {
        super(GET_HISTORY, auther);
    }

}