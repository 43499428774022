import { Action } from '../Action';

export interface MFAProps {
    mfa: string,
}

export interface CaptchaProps {
    captcha: string,
}

export abstract class MFAAction<T, R extends MFAProps> extends Action<T, R> {

}
export abstract class CaptchaAction<T, R extends CaptchaProps> extends Action<T, R> { }
