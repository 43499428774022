
export class ParseCarbon {

    public static parse(amount?: number, complete = false) : string | undefined {
        if (!amount)
            return '0KG';

        if (amount >= 1000)
            return `${ Number((amount / 1000).toFixed(0)).toLocaleString('es-US') } T${ complete ? 'ONS' : '' }`;

        return `${ amount.toFixed(0) } KG`;
    }

    public static carbonToDolars(amount?: number) : number {
        if (!amount)
            return 0;
        return Math.ceil(amount * 0.2);
    }

}