import { Method } from 'axios';
import { RouteBuilder } from './RouteBuilder';


export class Route {

    private readonly requiredParams: number;

    constructor(
        public readonly endpoint: string,
        // eslint-disable-next-line no-unused-vars
        public readonly type: Method,
    ) {
        const expr = new RegExp(/(({)(.+?)(}))/, 'g');
        this.requiredParams = endpoint.match(expr)?.length || 0;
    }


    public compileRoute(...args: string[]) : RouteBuilder {
        if (!args && this.requiredParams || args && args.length < this.requiredParams)
            throw new Error('You are missing parameters.');

        let index = 0;
        let product = '';
        let ap = false;

        for (let i = 0; i < this.endpoint.length; i++) {
            const char = this.endpoint[i];

            if (!ap && char !== '{')
                product += char;

            if (char === '{') {
                ap = true;
                product += args![index]; // We force since this won't be touched without args.
                index++;
            } else if (char === '}')
                ap = false;

        }

        return RouteBuilder
            .create()
            .withEndpoint(product);
    }

}