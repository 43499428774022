import { Route } from '../Routes/Route';
import axios, { AxiosError, AxiosRequestConfig, AxiosInstance } from 'axios';
import { API_URL, Auther } from '../Auther';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import { SWRConfiguration } from 'swr/dist/types';
import { RouteBuilder } from '../Routes/RouteBuilder';
import * as Sentry from '@sentry/nextjs';

interface ActionConfiguration {
    emitError?: boolean,
    authorize?: boolean,
    toastError?: boolean,
    revalidateToken?: boolean,
    onlyToastError?: boolean,
    // eslint-disable-next-line no-unused-vars
    handleAxiosError?: (error: AxiosError) => boolean,
    rateLimitTime?: number
    announceRateLimit?: boolean
}

type MightNotBeThere<T> = T | undefined;

const ActionStore: Record<string, any> = {};

export abstract class Action<Response, Request = undefined> {

    private static _client: AxiosInstance;
    public static get client() : AxiosInstance {
        if (!Action._client)
            Action._client = axios.create({
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            });
        return Action._client;
    }

    private headers?: Record<string, string>;
    private readonly body?: Request | FormData | undefined;

    protected route: Route;
    protected readonly bridge: Auther;

    protected actionConf: ActionConfiguration;

    private tries: number;

    get identifier() {
        const prototypeOf = Reflect.getPrototypeOf(this);
        const internal = this.route.type + ': ' + this.route.endpoint;
        return prototypeOf.constructor.name + ': ' + internal;
    }

    private get store() : any {
        const _store = ActionStore[this.identifier];
        if (!_store)
            ActionStore[this.identifier] = {};
        return ActionStore[this.identifier];
    }

    get lastExecutionTime() : number {
        return this.store.lastExecutionTime || 0;
    }

    set lastExecutionTime(time: number) {
        this.store.lastExecutionTime = time;
    }

    protected constructor(route: Route, bridge: Auther, body?: Request) {
        this.route = route;
        this.body = body;

        this.bridge = bridge;
        this.tries = 0;

        this.actionConf = {
            authorize: true,
            emitError: true,
            toastError: true,
            revalidateToken: false,
            rateLimitTime: 200, // 200 ms
            announceRateLimit: false,
        };
    }

    public getBody(): Request | FormData | undefined {
        return this.body;
    }

    public addHeader(header: string, content: string): void {
        if (!this.headers)
            this.headers = {};


        this.headers[header] = content;
    }

    public getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute();
    }

    public removeAuthorization() {
        this.actionConf.authorize = false;
        return this;
    }

    protected handleResponse(response: any) : Response {
        return response;
    }

    public async submit(config?: ActionConfiguration): Promise<Response> {
        const actionConf = {
            ...this.actionConf,
            ...config,
        };

        const now = Date.now();

        if (this.lastExecutionTime && now - this.lastExecutionTime < actionConf.rateLimitTime) {
            if (actionConf.announceRateLimit)
                toast.warn('Hey! Slow down. You are doing this too fast.');
            console.warn(`Action ${ this.constructor.name } is executing too fast.`);
            return;
        }

        this.lastExecutionTime = now;

        const _url = this.getCompiledRoute()
            .withBase(API_URL);

        if (this.route.type === 'GET' && this.body && !(this.body instanceof FormData))
            Object.entries(this.body).forEach(([ key, value ]) => _url.withParam(key, value));

        const url = _url.build();

        const conf = {
            method: this.route.type,
            url,
            headers: {
                ...this.headers,
            },
            data: this.route.type !== 'GET' ? this.getBody() : undefined,
        } as AxiosRequestConfig;

        if (actionConf.authorize)
            conf.headers = {
                ...conf.headers,
                Authorization: `Bearer ${ this.bridge.getActiveToken() }`,
            };

        try {
            const result = await Action.client(conf);
            this.tries = 0;

            return this.handleResponse(result.data);
        } catch (err) {


            if (!actionConf.emitError)
                return;

            // Tell Sentry
            let extraSentry: any = undefined;

            if (axios.isAxiosError(err)) {
                const response = err.response;
                extraSentry = {
                    actionResponse: JSON.stringify({
                        data: response.data,
                        headers: response.headers,
                        status: response.status,
                        statusText: response.statusText,
                    }),
                };
            }

            Sentry.captureException(err, {
                tags: {
                    action: this.constructor.name,
                },
                extra: {
                    ...extraSentry,
                    actionData: JSON.stringify(conf),
                },
            });

            if (axios.isAxiosError(err) && actionConf.handleAxiosError) {
                const shouldEmitError = actionConf.handleAxiosError(err as AxiosError);
                if (!shouldEmitError)
                    return;
            }

            if (axios.isAxiosError(err) && err.response && actionConf.authorize) {
                const errorMessage = err.response.data.message || 'Something went wrong';

                if (err.response.status === 403 && this.tries < 2 && actionConf.revalidateToken) {
                    await this.bridge.refreshTokenReview(true);
                    this.tries++;
                    console.info('Forcing refresh of the token.');
                    return await this.submit();
                }

                if (err.response.status !== 403 && actionConf.toastError)
                    toast.error(errorMessage);

                err.message = errorMessage;

                if (typeof actionConf.onlyToastError === 'undefined' || !actionConf.onlyToastError)
                    throw err;

            } else if (err instanceof Error && actionConf.toastError)
                toast.error(err.message || 'Something went wrong');

            if (typeof actionConf.onlyToastError === 'undefined' || !actionConf.onlyToastError)
                throw err;
        }
    }

    useSWR(condition = true, actionConf?: ActionConfiguration) {
        return (conf?: SWRConfiguration<MightNotBeThere<Response>, unknown>) =>
            useSWR<MightNotBeThere<Response>>(
                () => condition
                    ? this.getCompiledRoute()
                        .withBase(API_URL)
                        .build()
                    : null,
                () => this.submit(actionConf),
                conf,
            );
    }

}