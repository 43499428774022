import React, { useRef } from 'react';
import style from './BurgerButton.module.scss';

export default function BurgerButton({ onClick, className, active }: {
    onClick?: React.MouseEventHandler<HTMLButtonElement>,
    active: boolean;
    className?: string
}) {

    const ref = useRef<HTMLButtonElement>(null);

    const handleClick = (target: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick)
            onClick(target);

        if (ref.current)
            ref.current.classList.toggle(style.active);

    };

    return (
        <div data-cy="burger-btn" className={ className }>
            <button className={ `${ style.button } ${ active ? style.active : '' }` } onClick={ handleClick }>
                <span />
                <span />
                <span />
            </button>
        </div>
    );
}