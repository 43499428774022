import { createContext, ReactNode, useContext } from 'react';
import { MetaAPI } from '../API/MetaAPI';
import { useAuth } from './AuthContext';

interface ICarbonContext {
    amount: number,
    requestReload: () => void,
}

const CarbonContext = createContext<Partial<ICarbonContext>>({});

export const CarbonProvider = ({ children }: { children: ReactNode }) => {

    const auth = useAuth();
    const { data: carbon, mutate } = MetaAPI.getInstance().getCarbon().useSWR(!!auth.logged)();

    return (
        <CarbonContext.Provider
            value={ {
                amount: carbon?.amount || 0,
                requestReload: () => {
                    if (mutate)
                        mutate().then();
                },
            } }
        >
            { children }
        </CarbonContext.Provider>
    );

};

export function useCarbon() : Partial<ICarbonContext> {
    return useContext(CarbonContext);
}