import { Action } from './Action';
import { AdoptionDetails } from '../EntityTypes/AdoptionDetails';
import { Auther } from '../Auther';
import { GET_ADOPTIONS } from '../Routes/Routes';


export class GetAdoptions extends Action<Array<AdoptionDetails>> {

    constructor(bridge: Auther) {
        super(GET_ADOPTIONS, bridge);
        this.actionConf.authorize = false;
    }

}