import { API_URL } from '../Auther';
import { MetaAPI } from '../MetaAPI';

interface Payload {
    type: 'connected' | string,
    message: any,
}

// eslint-disable-next-line no-unused-vars
export type EventConsumer<T> = (payload: T, ok: () => void) => void;

export class EventHandler {

    private listeners: Map<string, {
        handlers: Array<EventConsumer<unknown>>,
        pattern: RegExp,
    }> = new Map();

    // We need this var since eventsource for some reason throws and error on start.
    private initialized: boolean;

    private connected: boolean;

    private readonly requiredAuth: boolean;
    private authenticated = false;

    private eventSource?: EventSource;
    private clientId?: string;

    constructor(auth: boolean) {
        this.requiredAuth = auth;
        this.connected = false;
        this.initialized = false;
    }

    public isAuth() {
        return this.authenticated;
    }

    private handleEvent(payload: Payload) {
        if (payload.type === 'connected')
            this.handleConnected(payload.message);

        this.listeners.forEach(({ handlers, pattern }) => {
            let newList = handlers;

            if (pattern.exec(payload.type)) {
                handlers.forEach(exec => {
                    exec(payload.message, () => {
                        newList = newList.filter(current => current !== exec);
                    });
                });

                this.listeners.set(pattern.toString(), {
                    handlers: newList,
                    pattern,
                });
            }

        });

    }

    private handleError(disconnected: boolean) {
        if (disconnected && this.connected) {
            this.connected = false;
            this.reconnect();
        }

    }

    private handleConnected(message: { id: string }) {
        this.clientId = message.id;
        console.info(`Client ${ this.clientId } connected`);
        if (this.requiredAuth)
            this.authClient();
        this.connected = true;
        this.initialized = true;
    }

    private authing = false;
    private authClient() {
        if (this.clientId && !this.isAuth() && !this.authing) {
            this.authing = true;
            MetaAPI.getInstance().authEventSource(this.clientId).submit().then(() => {
                console.info('Client Authenticated');
                this.authenticated = true;
                this.authing = false;
            });
        }

    }


    // eslint-disable-next-line no-unused-vars
    on<T>(event: RegExp, handler: EventConsumer<T>) {
        const key = event.toString();

        let listeners = this.listeners.get(key);

        if (!listeners)
            listeners = {
                handlers: [ handler ],
                pattern: event,
            };
        else
            listeners.handlers.push(handler);


        this.listeners.set(key, listeners);
        return {
            off: () => {
                this.listeners.set(key, {
                    handlers: listeners.handlers.filter(current => current !== handler),
                    pattern: event,
                });
            },
        };
    }

    off(event: RegExp, handler: EventConsumer<unknown>) {
        const key = event.toString();

        const listeners = this.listeners.get(key);
        if (listeners) {
            this.listeners.set(key, {
                handlers: listeners.handlers.filter(current => current !== handler),
                pattern: event,
            });

            if (listeners.handlers.length === 0)
                this.listeners.delete(key);
        }

    }

    private reconnect() {
        const canRefresh = this.eventSource && !this.connected && this.initialized;
        if (canRefresh) {
            this.eventSource.close();
            this.connect(this.eventSource.url);
            console.info('Reconnecting Client...');

            const timeout = setTimeout(() => {
                if (this.connected)
                    clearTimeout(timeout);
                else {
                    console.info('No connection after 5 seconds, retrying...');
                    this.reconnect();
                }
            }, 5000);

        }
    }

    private connect(url: string) {
        if (this.connected)
            return;

        this.eventSource = new EventSource(url);

        this.eventSource.onmessage = (event: MessageEvent) => {
            const data = JSON.parse(event.data);
            this.handleEvent(data);
        };

        this.eventSource.onerror = () => {
            const state = this.eventSource.readyState;
            this.handleError(state === EventSource.CLOSED);
        };

        // We disconnect the event source when the client is closed

    }

    private static _eventHandler: EventHandler;

    public static newEventHandler(disableAuth = false) : EventHandler {

        if (EventHandler._eventHandler) {
            const isAuth = EventHandler._eventHandler.isAuth();
            if (!isAuth && !disableAuth)
                EventHandler._eventHandler.authClient();
            return EventHandler._eventHandler;
        }

        EventHandler._eventHandler = EventHandler.createClient(disableAuth);
        return EventHandler._eventHandler;
    }

    private static createClient(disableAuth: boolean) {
        const eventHandler = new EventHandler(!disableAuth);
        eventHandler.connect(API_URL + '/eventsource/subscribe');

        return eventHandler;
    }

}