import { Action } from './Action';
import { MetaCitizen } from '../EntityTypes/GetMetaCitizen';
import { Auther } from '../Auther';
import { GET_METACITIZEN } from '../Routes/Routes';
import { RouteBuilder } from '../Routes/RouteBuilder';


export class GetMetaCitizen extends Action<MetaCitizen> {

    private readonly index?: string;

    constructor(bridge: Auther, index?: string) {
        super(GET_METACITIZEN, bridge);
        this.index = index;
    }

    public getCompiledRoute(): RouteBuilder {
        const route = this.route.compileRoute();
        if (this.index)
            return route.withEndpoint(route.build() + `/${ this.index }`);
        return route;
    }

}
