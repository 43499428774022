import { Action } from '../Action';
import { Auther } from '../../Auther';
import { MINT_MOD } from '../../Routes/Routes';
import { RouteBuilder } from '../../Routes/RouteBuilder';

interface IMint {
    mintReceiptId: string,
    swapId: string,
    mintId: string,
}

export interface MintRequest {
    templateId?: string,
    placeId?: string,
}

export class Mint extends Action<IMint, { placeId?: string }> {

    private readonly request: MintRequest;

    constructor(bridge: Auther, req: MintRequest) {
        super(MINT_MOD, bridge, {
            placeId: req.placeId,
        });
        this.request = req;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(this.request.templateId);
    }

}