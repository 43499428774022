import { Auther } from '../../Auther';
import { GetP2EBank } from './GetBank';
import { GetP2EClient } from './GetClient';
import { GetUserStakeInfo } from './GetStakeInfo';
import { StakeForP2EClient } from './StakeClient';
import { GetP2ETotalEarnedAmount } from './GetTotalEarned';
import { GetP2EClientLeaderboard, P2EClientLeaderboardBody } from './GetLeaderboard';

export class PlayToEarnActions {

  private readonly auther: Auther

  constructor(auther: Auther) {
      this.auther = auther;
  }

  public getTotalEarned() {
      return new GetP2ETotalEarnedAmount(this.auther);
  }

  public getBank() {
      return new GetP2EBank(this.auther);
  }

  public getClient(id: string) {
      return new GetP2EClient(this.auther, id);
  }

  public getClientLeaderboard(id: string, params: P2EClientLeaderboardBody) {
      return new GetP2EClientLeaderboard(this.auther, id, params);
  }

  public getUserStakeInfo() {
      return new GetUserStakeInfo(this.auther);
  }

  public stakeForClient(id: string) {
      return new StakeForP2EClient(this.auther, id);
  }
}