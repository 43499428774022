import { Action } from './Action';
import { Auther } from '../Auther';
import { GET_CARBON_BANKS } from '../Routes/Routes';

export interface CarbonBanks {
    total: number;
    tokens: number;
    players: number;
    mymetaverse: number;
}

export class GetCarbonBanks extends Action<CarbonBanks> {

    constructor(bridge: Auther) {
        super(GET_CARBON_BANKS, bridge);
        this.actionConf.authorize = false;
    }

}