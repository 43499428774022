import { Action } from './Action';
import { AdoptionDetails } from '../EntityTypes/AdoptionDetails';
import { GET_ADOPTION_DETAILS } from '../Routes/Routes';
import { Auther } from '../Auther';
import { RouteBuilder } from '../Routes/RouteBuilder';


export class GetAdoptionDetails extends Action<AdoptionDetails> {

    private readonly id: string;

    constructor(bridge: Auther, id: string) {
        super(GET_ADOPTION_DETAILS, bridge);
        this.id = id;
    }

    getCompiledRoute(): RouteBuilder {
        return this.route.compileRoute(this.id);
    }

}