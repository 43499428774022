import Link from 'next/link';
import styles from './MobileHeader.module.scss';
import { Fragment, MouseEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'antd';
import SepLine from '../../common/SepLine/SepLine';
import { Route } from '../HeaderWrapper/HeaderWrapper';

interface MenuOption {
    name: string,
    link?: string,
    sub?: Array<MenuOption>
}

interface MobileMenuListProps {
    options: Array<MenuOption>
}

type ActiveMenu = {
    parent?: ActiveMenu,
    active: MenuOption,
}

export function MobileMenuList({ options }: MobileMenuListProps) {

    const [ activeSub, setActiveSub ] = useState<ActiveMenu>();

    const handleSubMenu = (ev: MouseEvent<HTMLAnchorElement>, menu: MenuOption) => {
        ev.stopPropagation();
        ev.preventDefault();
        setActiveSub(current => ({
            parent: current,
            active: menu,
        }));
    };

    const closeSubMenu = (ev: MouseEvent<HTMLAnchorElement>) => {
        ev.stopPropagation();
        ev.preventDefault();
        setActiveSub(current => current?.parent);
    };

    const handleBreadcrumb = () => {
        const handle = (prior: ActiveMenu, last?: string) => {
            const build = last ? `${ prior.active.name } / ${ last }` : prior.active.name;
            if (prior.parent)
                return handle(prior.parent, build);
            return build;
        };
        return handle(activeSub);
    };

    const renderRouteLink = (route: Route) => {
        const isExternal = route.link && (route.link.startsWith('http') || route.link.startsWith('https'));

        if (isExternal)
            return (
                <a href={ route.link } target="_blank" rel="noopener noreferrer">
                    { route.name }
                </a>
            );

        return (
            route.link
                ? <Link href={ route.link }>
                    {route.name}
                </Link>
                : <a onClick={ ev => handleSubMenu(ev, route) }>
                    {route.name}
                    <FontAwesomeIcon
                        icon={ faAngleRight }
                        style={ { marginLeft: '6px' } }
                    />
                </a>
        );
    };

    return <>
        <div>
            <div className={ styles.menuOptions }>
                {
                    activeSub
                    && <Row justify='center' style={ { marginBottom: 20 } } gutter={ [ 0, 5 ] }>
                        <Col span={ 24 }>
                            { handleBreadcrumb() }
                        </Col>
                        <Col span={ 4 }>
                            <SepLine width={ 100 } />
                        </Col>
                    </Row>
                }
                {
                    (activeSub ? activeSub.active.sub : options)
                        .map((option, index) =>
                            <div key={ index }>
                                {renderRouteLink(option)}
                            </div>,
                        )
                }
                {
                    activeSub
                    && <Row justify='center' style={ { marginTop: 20 } }>
                        <Col span={ 4 }>
                            <SepLine width={ 100 } />
                        </Col>
                        <Col span={ 24 }>
                            <a onClick={ closeSubMenu }>
                                <FontAwesomeIcon
                                    icon={ faCircleLeft }

                                />
                                <span
                                    style={ { marginLeft: '6px' } }
                                >BACK</span>
                            </a>
                        </Col>

                    </Row>
                }

            </div>

        </div>

    </>;
}