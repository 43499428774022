import { Auther } from '../../Auther';
import { GetTemplates } from './GetTemplates';
import { GetTemplate } from './GetTemplate';
import { Mint, MintRequest } from './Mint';
import { Ping } from './Ping';
import { Join } from './Join';
import { Leave } from './Leave';

export interface ITemplate {
    id: string,
    name: string,
    description: string,
    displayId: string,
    image: string,
    maximumAvailable: number,
    currentMinted: number,
    availableDate?: number,
    tokenId: string,
    formulaType: string,
    currentCost: number,
    formulaProps: any,
}

export class MintOnDemandActions {

    private readonly auther: Auther

    constructor(auther: Auther) {
        this.auther = auther;
    }

    public getTemplate(id: string) {
        return new GetTemplate(this.auther, id);
    }

    public getTemplates() {
        return new GetTemplates(this.auther);
    }

    public mint(req: MintRequest) {
        return new Mint(this.auther, req);
    }

    public ping(placeId: string) {
        return new Ping(this.auther, { placeId });
    }

    public join(templateId: string) {
        return new Join(this.auther, templateId);
    }

    public leave(placeId: string) {
        return new Leave(this.auther, { placeId });
    }

}