import { Action } from './Action';
import { GET_CARBON } from '../Routes/Routes';
import { Auther } from '../Auther';

export interface UserCarbonAccount {
    amount: number
}

export class GetCarbon extends Action<UserCarbonAccount> {

    constructor(bridge: Auther) {
        super(GET_CARBON, bridge);
    }

}