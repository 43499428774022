import { Action } from '../Action';
import { Auther } from '../../Auther';
import { SHOP_CREATE_PAYMENT } from '../../Routes/Routes';
import { ContactDetails } from '../../EntityTypes/SensitiveDetails';

export interface PaymentDetails {
    redirectUri: string,
    id: string,
    foreignId: string
}

interface CreatePaymentBody {
    gateway: string,
    itemId: string,
    extraProps?: Record<string, unknown>,
    contactDetails: ContactDetails,
    amount: number,
}

export type PaymentGateway = 'paypal' | 'circle' | 'coingate';

export class CreatePayment extends Action<PaymentDetails, CreatePaymentBody> {

    private readonly gateway: PaymentGateway;
    private readonly itemId: string;
    private readonly extraProps?: Record<string, unknown>;
    private readonly contactDetails: ContactDetails;
    private readonly amount: number;

    constructor(bridge: Auther, gateway: PaymentGateway, itemId: string, contactDetails: ContactDetails, amount: number, extraProps?: Record<string, unknown>) {
        super(SHOP_CREATE_PAYMENT, bridge);
        this.gateway = gateway;
        this.itemId = itemId;
        this.extraProps = extraProps;
        this.contactDetails = contactDetails;
        this.amount = amount;
    }

    getBody(): CreatePaymentBody {
        return {
            gateway: this.gateway,
            itemId: this.itemId,
            extraProps: this.extraProps,
            contactDetails: this.contactDetails,
            amount: this.amount,
        };
    }

}